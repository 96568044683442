import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SubButton from 'components/SubButton';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Lumina = props => {
  const { slug } = props;
  return (
    <Root>
      <h4>Part of the</h4>
      <h2>Lumina Brentwood Masterplan</h2>
      <Container>
        <img
          src={require('src/assets/images/properties/lumina-masterplan.jpg')}
          alt="Lumina Brentwood"
        />
        <Link
          to={
            slug === 'lumina-starling'
              ? '/communities/lumina-alpha'
              : '/communities/lumina-starling'
          }
        >
          <Property
            image={
              slug === 'lumina-starling'
                ? require('src/assets/images/properties/lumina-alpha.jpg')
                : require('src/assets/images/properties/lumina-starling.jpg')
            }
          >
            <h3>Lumina {slug === 'lumina-starling' ? 'Alpha' : 'Starling'}</h3>
            <SubButton white>View Community</SubButton>
            <div className="overlay" />
          </Property>
        </Link>
        <Link
          to={
            slug === 'lumina-waterfall'
              ? '/communities/lumina-alpha'
              : '/communities/lumina-waterfall'
          }
        >
          <Property
            image={
              slug === 'lumina-waterfall'
                ? require('src/assets/images/properties/lumina-alpha.jpg')
                : require('src/assets/images/properties/lumina-waterfall.jpg')
            }
          >
            <h3>
              Lumina {slug === 'lumina-waterfall' ? 'Alpha' : 'Waterfall'}
            </h3>
            <SubButton white>View Community</SubButton>
            <div className="overlay" />
          </Property>
        </Link>
      </Container>
    </Root>
  );
};

export default Lumina;

const Root = styled.div`
  margin: ${vw(80)} ${vw(20)} ${vw(40)};
  img {
    width: 100%;
    height: ${vw(160)};
    object-fit: cover;
    object-position: right;
  }
  h4 {
    color: ${({ theme }) => theme.color.blue};
  }
  h2 {
    margin: ${vw(12)} 0 ${vw(32)};
  }
  h3 {
    margin-bottom: ${vw(12)};
    color: white;
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(80)} ${vwTablet(60)} 0;
    img {
      height: ${vwTablet(320)};
      grid-column: 1;
      grid-row: 1 / 3;
      object-position: 90%;
    }
    h2 {
      margin: ${vwTablet(12)} 0 ${vwTablet(32)};
    }
    h3 {
      margin-bottom: ${vwTablet(12)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(140)} 0 0;
    padding: ${vwDesktop(80)} ${vwDesktop(120)};
    background-color: ${({ theme }) => theme.color.grey6};
    img {
      height: ${vwDesktop(320)};
      grid-column: 1;
      grid-row: 1 / 3;
      object-position: 90%;
    }
    h2 {
      margin: ${vwDesktop(12)} 0 ${vwDesktop(40)};
    }
    h3 {
      margin-bottom: 0;
    }
  }
`;

const Container = styled.div`
  @media (min-width: ${media.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: ${vwTablet(24)};
  }
  @media (min-width: ${media.desktop}) {
    grid-gap: ${vwDesktop(24)};
  }
`;

const Property = styled.div`
  background-image: url(${props => props.image});
  background-size: 200%;
  background-position: left;
  width: 100%;
  height: ${vw(160)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${vw(16)};
  position: relative;
  margin-top: ${vw(24)};
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    );
    z-index: 1;
  }
  h3,
  button {
    z-index: 2;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(148)};
    padding: ${vwTablet(18)} ${vwTablet(24)};
    margin-top: 0;
    grid-column: 2;
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(148)};
    padding: ${vwDesktop(16)} ${vwDesktop(24)};
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    &:hover .subbutton-line {
      left: 0;
    }
  }
`;
