import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Parallax, withController } from 'react-scroll-parallax';

import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = props => {
  const [scale, setScale] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);

  const parallax = document.getElementsByClassName('parallax-inner');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [props.item.title]);

  const handleScroll = e => {
    setScale(
      e.target.scrollingElement.scrollTop /
        e.target.scrollingElement.clientHeight
    );
    if (
      e.target.scrollingElement.scrollTop >
      e.target.scrollingElement.clientHeight / 2
    ) {
      let style = parallax[0].style.transform;
      setHasScrolled(true);
      parallax[0].style.transform = style;
    } else {
      setHasScrolled(false);
    }
  };

  const { item } = props;

  return (
    <Root image={item.image}>
      <h1>{item.title}</h1>
      <p className="body-large">{item.tagline}</p>
      <Parallax y={['-100px', '100px']} disabled={hasScrolled}>
        <ParallaxImage
          className="desktop-image"
          src={item.image}
          alt={item.title}
        />
      </Parallax>
      <div className="overlay" style={{ opacity: 0.3 * scale }} />
    </Root>
  );
};

export default withController(Hero);

const Root = styled.div`
  width: 100%;
  height: ${vw(400)};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  color: white;
  padding: ${vw(24)} ${vw(20)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  h1 {
    margin-bottom: ${vw(16)};
    z-index: 2;
  }
  p {
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${vw(240)};
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6)
    );
    mix-blend-mode: multiply;
    z-index: 1;
  }
  .overlay {
    display: none;
  }
  .parallax-outer {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(680)};
    padding: ${vwTablet(40)} ${vwTablet(60)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    &::after {
      height: ${vwTablet(240)};
    }
    .body-large {
      font-size: ${vwTablet(28)};
    }
  }
  @media (min-width: ${media.desktop}) {
    height: 100vh;
    padding: ${vwDesktop(80)} ${vwDesktop(120)};
    overflow: hidden;
    h1 {
      margin-bottom: ${vwDesktop(24)};
    }
    &::after {
      height: ${vwDesktop(300)};
    }
    .body-large {
      font-size: ${vwDesktop(28)};
    }
    .overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0;
      mix-blend-mode: multiply;
    }
    .parallax-outer {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .parallax-inner {
      width: 100%;
      height: 100%;
    }
  }
`;
