import React from 'react';
import styled from 'styled-components';

import Wrapper from 'components/PageLayout/elements/Wrapper';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Info = () => {
  return (
    <Root>
      <Wrapper>
        <h4>Communities</h4>
        <h3>Our Unwavering Commitment To You</h3>
        <p className="body-1">
          From the day we start construction to years after you’ve moved in, we
          treat every home we build like it was our own. We are committed to
          making sure every homeowner has complete peace of mind. If you
          discover any service and/or warranty issues with your home, please
          complete the Service Request form below with a brief description of
          the problem, and one of our service representatives will contact you
          as soon as possible.
        </p>
      </Wrapper>
    </Root>
  );
};

export default Info;

const Root = styled.div`
  margin-bottom: ${vw(84)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(140)};
    p {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
    p {
      max-width: ${vwDesktop(588)};
    }
  }
`;
