import React from 'react';

export default [
  {
    title: 'Minerva: Changing the Face of Leadership in BC',
    image: require('src/assets/images/about/awards/minerva.png'),
  },
  {
    title: 'Jewels of Punjab: Leading Global Punjabi Personalities',
    image: require('src/assets/images/about/awards/jewels-of-punjab.png'),
  },
  {
    title: (
      <>
        Vancouver Magazine:
        <br /> Power 50
      </>
    ),
    image: require('src/assets/images/about/awards/vanmag.png'),
  },
  {
    title: 'Global Punjabi Society Awards 2015',
    image: require('src/assets/images/about/awards/gps.png'),
  },
  {
    title: 'Georgie Awards: Best Multi-Family Kitchen 2015',
    image: require('src/assets/images/about/awards/georgie.png'),
  },
  {
    title: (
      <>
        Award of Merit 2015 -<br /> High Rise (Skyway Tower)
      </>
    ),
    image: require('src/assets/images/about/awards/masonry.png'),
  },
];
