import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import Wrapper from 'components/PageLayout/elements/Wrapper';

import awardData from 'src/data/recognition';
import publicationData from 'src/data/publications';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Recognition = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
  };

  return (
    <Root>
      <Wrapper>
        <h4>Recognition</h4>
        <h3>A Commitment to Quality</h3>
        <p className="body-1">
          Thind’s commitment to building quality homes and creating value for
          homeowners has resulted in numerous awards. Thind is an active
          participant in The Georgie Awards and is proud of being a part of an
          inspirational and innovative industry that develops high-calibre
          homes.
        </p>
        <img
          src={require('src/assets/images/about/commitment-to-quality.jpg')}
          alt="A Commitment to Quality"
          className="main-image"
        />
        <h3>Awards and Recognition</h3>
        <p className="body-1">
          Daljit Thind’s continued success in real estate as well as his
          philanthropic and community involvement, have not gone unnoticed.
          Vancouver Magazine recently voted him one of the Power 50 — a list
          which recognizes the most influential leaders in Vancouver which
          included the likes of Christy Clark and Jim Pattison.
        </p>
        <Awards>
          {awardData.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.title} />
              <p className="body-2">{item.title}</p>
            </div>
          ))}
        </Awards>
        <h3>Publications</h3>
        <p className="body-1">
          As one of Vancouver’s most influential real estate developers, Daljit
          Thind’s story of success has become an inspiration to many, and he has
          been featured in numerous publications.
        </p>
      </Wrapper>
      <div>
        <StyledSlider {...settings}>
          {publicationData.map((item, index) => (
            <Slide key={index}>
              <div className="wrapper">
                <img
                  src={item.image}
                  alt={item.publication}
                  className="desktop-image"
                />
                <div className="content">
                  <img
                    src={require('src/assets/images/icons/quote.svg')}
                    alt="quote"
                    className="quote"
                  />
                  <p className="body-2">{item.quote}</p>
                  {item.title && <h4 className="black">{item.title}</h4>}
                  <h4>{item.publication}</h4>
                </div>
              </div>
            </Slide>
          ))}
        </StyledSlider>
      </div>
    </Root>
  );
};

export default Recognition;

const Root = styled.div`
  margin: ${vw(80)} 0;
  img {
    width: 100%;
  }
  .main-image {
    margin: ${vw(32)} 0 ${vw(60)};
    height: ${vw(160)};
    object-fit: cover;
  }
  h3 {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(63)} 0 ${vwTablet(140)};
    .main-image {
      margin: ${vwTablet(40)} 0 ${vwTablet(80)};
      height: ${vwTablet(240)};
    }
    h3 {
      margin-bottom: ${vwTablet(24)};
    }
    p {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(140)} 0 ${vwDesktop(140)};
    .main-image {
      margin: ${vwDesktop(40)} 0 ${vwDesktop(80)};
      height: ${vwDesktop(300)};
    }
    h3 {
      margin-bottom: ${vwDesktop(24)};
    }
    p {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Awards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin: ${vw(40)} 0 ${vw(63)};
  > div {
    width: ${vw(128)};
    margin-bottom: ${vw(24)};
  }
  img {
    margin-bottom: ${vw(4)};
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(40)} 0 ${vwTablet(80)};
    > div {
      width: ${vwTablet(180)};
      margin-bottom: ${vwTablet(40)};
    }
    img {
      margin-bottom: ${vwTablet(8)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(40)} 0 ${vwDesktop(80)};
    justify-content: flex-start;
    > div {
      width: ${vwDesktop(180)};
      margin-bottom: ${vwDesktop(40)};
      margin-right: ${vwDesktop(24)};
    }
    > div:nth-of-type(4n) {
      margin-right: 0;
    }
    img {
      margin-bottom: ${vwDesktop(8)};
    }
  }
`;

const StyledSlider = styled(Slider)`
  background-color: ${({ theme }) => theme.color.grey6};
  height: ${vw(534)};
  margin-top: ${vw(32)};
  .slick-dots {
    bottom: ${vw(40)};
  }
  li {
    width: auto;
    height: auto;
    margin: 0;
    margin-right: ${vw(8)};
    &:last-of-type {
      margin-right: 0;
    }
    button {
      padding: 0;
      width: ${vw(8)};
      height: ${vw(8)};
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.grey5};
      &::before {
        display: none;
      }
    }
    &.slick-active button {
      background-color: ${({ theme }) => theme.color.blue};
    }
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(548)};
    height: auto;
    margin: ${vwTablet(40)} auto 0;
    position: relative;
    .slick-dots {
      bottom: ${vwTablet(-32)};
    }
    li {
      margin-right: ${vwTablet(8)};
      button {
        width: ${vwTablet(8)};
        height: ${vwTablet(8)};
      }
    }
    .slick-arrow::before {
      content: '';
    }
    .slick-arrow {
      width: ${vwTablet(18)};
      height: ${vwTablet(32)};
    }
    .slick-prev {
      left: ${vwTablet(-50)};
      background-image: url(${require('src/assets/images/icons/arrow-left.svg')});
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .slick-next {
      right: ${vwTablet(-50)};
      background-image: url(${require('src/assets/images/icons/arrow-right.svg')});
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      align-self: center;
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(794)};
    height: auto;
    margin: ${vwDesktop(40)} auto 0;
    .slick-dots {
      bottom: ${vwDesktop(-24)};
    }
    li {
      margin-right: ${vwDesktop(8)};
      button {
        width: ${vwDesktop(8)};
        height: ${vwDesktop(8)};
      }
    }
    .slick-arrow {
      width: ${vwDesktop(18)};
      height: ${vwDesktop(50)};
    }
    .slick-prev {
      left: ${vwDesktop(-50)};
    }
    .slick-next {
      right: ${vwDesktop(-50)};
    }
  }
`;

const Slide = styled.div`
  padding: ${vw(40)} ${vw(32)};
  color: ${({ theme }) => theme.color.black};
  .quote {
    width: ${vw(18)};
    height: ${vw(16)};
    margin-bottom: ${vw(12)};
  }
  p {
    font-style: italic;
    font-weight: 300;
    margin-bottom: ${vw(32)};
  }
  .black {
    color: ${({ theme }) => theme.color.black};
  }
  h4 {
    margin-bottom: ${vw(4)};
  }
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(48)} ${vwTablet(40)};
    .quote {
      width: ${vwTablet(18)};
      height: ${vwTablet(16)};
      margin-bottom: ${vwTablet(12)};
    }
    p {
      margin-bottom: ${vwTablet(32)};
    }
    h4 {
      margin-bottom: ${vwTablet(4)};
    }
    .desktop-image {
      display: block;
      width: ${vwTablet(171)};
      height: ${vwTablet(208)};
      margin-right: ${vwTablet(32)};
    }
    .wrapper {
      display: flex;
      align-items: center;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(68)} ${vwDesktop(48)};
    .quote {
      width: ${vwDesktop(18)};
      height: ${vwDesktop(16)};
      margin-bottom: ${vwDesktop(12)};
    }
    p {
      margin-bottom: ${vwDesktop(24)};
    }
    h4 {
      margin-bottom: ${vwDesktop(4)};
    }
    .desktop-image {
      display: block;
      width: ${vwDesktop(350)};
      height: ${vwDesktop(424)};
      margin-right: ${vwDesktop(48)};
    }
    .content {
      max-width: ${vwDesktop(300)};
    }
  }
`;
