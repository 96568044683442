import React from 'react';
import styled from 'styled-components';

import PageLayout from 'components/PageLayout/PageLayout';
import History from './sections/History';
import Team from './sections/Team';
import Recognition from './sections/Recognition';

import media from 'src/styles/media';

const sections = [
  {
    title: 'History',
    section: <History />,
  },
  {
    title: 'Team',
    section: <Team />,
  },
  {
    title: 'Recognition',
    section: <Recognition />,
  },
];

const About = () => {
  return (
    <Root id="about">
      <PageLayout
        title="About Us"
        description="Like many journeys, Thind’s ascent to one of Vancouver’s leading real estate developer began from humble beginnings."
        hero={require('src/assets/images/about/thind-team-update.jpg')}
        sections={sections}
        about
      />
    </Root>
  );
};

export default About;

const Root = styled.div`
  overflow-x: hidden;
  @media (min-width: ${media.tablet}) {
    overflow-x: visible;
  }
`;
