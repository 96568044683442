import { useLayoutEffect } from 'react';
import { withController } from 'react-scroll-parallax';

const ParallaxCache = props => {
  useLayoutEffect(() => {
    const handler = () => props.parallaxController.update();
    window.addEventListener('load', handler);
    return () => window.removeEventListener('load', handler);
  }, [props.parallaxController]);

  return null;
};

export default withController(ParallaxCache);
