import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Input = props => {
  const { label, name, id, type, required, pattern } = props;
  return (
    <Root>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        name={name}
        id={id}
        required={required}
        pattern={pattern}
      />
    </Root>
  );
};

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  pattern: PropTypes.string,
};

export default Input;

const Root = styled.div`
  margin-bottom: ${vw(24)};
  input {
    width: 100%;
    height: ${vw(48)};
    background-color: ${({ theme }) => theme.color.grey6};
    padding: 0 ${vw(16)};
    transition: 0.3s ease;
    border: 0;
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
  }
  input:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.color.blue};
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(24)};
    input {
      height: ${vwTablet(48)};
      padding: 0 ${vwTablet(16)};
      font-size: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(24)};
    input {
      height: ${vwDesktop(48)};
      padding: 0 ${vwDesktop(16)};
      font-size: ${vwDesktop(16)};
    }
  }
`;
