import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withController } from 'react-scroll-parallax';

import Button from 'components/Button';
import Map from './sections/Map';
import Hero from './sections/Hero';
import Details from './sections/Details';
import Lumina from './sections/Lumina';
import Navigation from './sections/Navigation';

import data from 'src/data/communities';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class SingleCommunity extends Component {
  handleLoad = () => {
    this.props.parallaxController.update();
  };

  handleNext = () => {
    const titles = Object.keys(data);
    if (titles.indexOf(this.props.match.params.id) === titles.length - 1) {
      this.props.history.push(`/communities/${titles[0]}`);
    } else {
      this.props.history.push(
        `/communities/${titles[titles.indexOf(this.props.match.params.id) + 1]}`
      );
    }
  };

  handlePrev = () => {
    const titles = Object.keys(data);
    if (titles.indexOf(this.props.match.params.id) === 0) {
      this.props.history.push(`/communities/${titles[titles.length - 1]}`);
    } else {
      this.props.history.push(
        `/communities/${titles[titles.indexOf(this.props.match.params.id) - 1]}`
      );
    }
  };

  renderCommunityName(next) {
    const titles = Object.keys(data);
    if (next) {
      if (titles.indexOf(this.props.match.params.id) === titles.length - 1) {
        return data[titles[0]].title;
      } else {
        return data[titles[titles.indexOf(this.props.match.params.id) + 1]]
          .title;
      }
    } else {
      if (titles.indexOf(this.props.match.params.id) === 0) {
        return data[titles[titles.length - 1]].title;
      } else {
        return data[titles[titles.indexOf(this.props.match.params.id) - 1]]
          .title;
      }
    }
  }

  render() {
    const item = data[this.props.match.params.id];

    return (
      <Root onLoad={this.handleLoad}>
        <Hero item={item} />
        <Desktop>
          <Container>
            <div>
              <img src={item.logo} alt={item.title} className="logo" />
              {item.website && (
                <div className="desktop-content">
                  <Button blue transparent href={item.website}>
                    Visit Website
                  </Button>
                </div>
              )}
            </div>
            <div>
              <p className="body-1">{item.description}</p>
              {item.website && (
                <div className="mobile-content">
                  <Button blue transparent href={item.website}>
                    Visit Website
                  </Button>
                </div>
              )}
              <div className="desktop-map">
                {item.map && <Map item={item} />}
              </div>
            </div>
          </Container>
          <Details item={item} />
        </Desktop>
        <div className="mobile-map">{item.map && <Map item={item} />}</div>
        {item.lumina && <Lumina slug={item.slug} />}
        <Navigation
          handleNext={this.handleNext}
          handlePrev={this.handlePrev}
          nextTitle={this.renderCommunityName(true)}
          prevTitle={this.renderCommunityName(false)}
          isGrey={this.props.match.params.id.includes('lumina')}
        />
      </Root>
    );
  }
}

export default withRouter(withController(SingleCommunity));

const Root = styled.div`
  color: ${({ theme }) => theme.color.black};
  padding: 0;
  .logo {
    width: ${vw(160)};
    margin: ${vw(60)} auto ${vw(40)};
  }
  .desktop-content {
    display: none;
  }
  .desktop-map {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: 0;
    .mobile-content {
      display: none;
    }
    .desktop-content {
      display: block;
    }
    .logo {
      width: ${vwTablet(180)};
      margin: 0 0 ${vwTablet(60)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    .logo {
      width: ${vwDesktop(180)};
      margin: 0 0 ${vwDesktop(80)};
    }
    .desktop-map {
      display: block;
    }
    .mobile-map {
      display: none;
    }
  }
`;

const Container = styled.div`
  padding: 0 ${vw(20)};
  .body-1 {
    margin: ${vw(24)} 0 ${vw(32)};
  }
  > div:first-of-type {
    text-align: center;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} ${vwTablet(60)} 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .body-1 {
      margin: 0 0 ${vwTablet(32)};
    }
    > div:first-of-type {
      text-align: left;
    }
    p {
      max-width: ${vwTablet(368)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(140)} ${vwDesktop(120)} 0;
    justify-content: flex-start;
    .body-1 {
      margin: 0 0 ${vwDesktop(32)};
    }
    p {
      max-width: ${vwDesktop(486)};
    }
    > div:first-of-type {
      margin-right: ${vwDesktop(111)};
    }
  }
`;

const Desktop = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
  }
`;
