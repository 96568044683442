import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Button from 'components/Button';
// import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ComingSoon = () => {
  const $video = React.createRef();

  useEffect(() => {
    $video.current.muted = true;
  }, []);

  return (
    <Root>
      <Parallax y={['80px', '-60px']}>
        {/* <DesktopImage
          src={require('src/assets/images/properties/highline-daytime.jpg')}
          alt="Highline"
        /> */}
        <DesktopVideo>
          <video autoPlay muted loop playsInline>
            <source src="https://player.vimeo.com/external/371715650.hd.mp4?s=aaa3cdf2c339218a32e78ebe7a077f0dbeaad510&amp;profile_id=174" />
          </video>
        </DesktopVideo>
      </Parallax>
      <Wrapper>
        <h4>Coming Soon</h4>
        <h2>Highline</h2>
        {/* <img
          src={require('src/assets/images/properties/highline-daytime.jpg')}
          alt="Highline"
          className="mobile-image"
        /> */}
        <video
          autoPlay
          muted
          playsInline
          loop
          className="mobile-image"
          ref={$video}
        >
          <source src="https://player.vimeo.com/external/371715650.hd.mp4?s=aaa3cdf2c339218a32e78ebe7a077f0dbeaad510&amp;profile_id=174" />
        </video>
        <p className="description body-1">
          Experience upscale living at the tallest tower on Beresford. Highline
          offers beautiful 1, 2, and 3-bedroom homes in the radiant centre of
          Metrotown.{' '}
          <span className="desktop">
            Featuring a 11th floor Club Highline for residents, you can live
            your best life with your own spa, fitness, and wellness centre.
            Surrounded by shops, food, parks, and transportation to keep you
            connected and moving. Make your move with Highline.
          </span>
        </p>
        <Stat>
          Location: <span>Burnaby</span>
        </Stat>
        <Stat>
          Homes: <span>327</span>
        </Stat>
        <Stat>
          Commercial: <span>10 floors of office with ground floor commercial space</span>
        </Stat>
        <Stat>
          Completion Date: <span>2023</span>
        </Stat>
        <Button blue transparent to="/communities/highline">
          View Community
        </Button>
      </Wrapper>
    </Root>
  );
};

export default ComingSoon;

const Root = styled.div`
  color: ${({ theme }) => theme.color.black};
  @media (min-width: ${media.tablet}) {
    display: flex;
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 ${vwDesktop(120)};
  }
  h4 {
    font-size: ${vw(12)};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(12)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(12)};
    }
  }
  img {
    width: 100%;
    height: ${vw(246)};
    object-fit: cover;
    margin: ${vw(32)} 0;
    @media (min-width: ${media.tablet}) {
      width: ${vwTablet(372)};
      height: ${vwTablet(399)};
      margin: ${vwTablet(120)} 0 0;
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(660)};
      height: ${vwDesktop(604)};
      margin: ${vwDesktop(100)} 0 0;
    }
  }
  .description {
    margin-bottom: ${vw(32)};
    @media (min-width: ${media.tablet}) {
      margin: ${vwTablet(32)} 0;
    }
    @media (min-width: ${media.desktop}) {
      margin: ${vwDesktop(32)} 0;
    }
  }
  .mobile-image {
    @media (min-width: ${media.tablet}) {
      display: none;
    }
  }
  .parallax-outer {
    z-index: 2;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: ${vw(32)} 0;
    @media (min-width: ${media.tablet}) {
      margin: 0;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  width: 100%;
  height: 100%;
  padding: ${vw(60)} ${vw(20)};
  .desktop {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} ${vwTablet(60)} ${vwTablet(80)} ${vwTablet(204)};
    margin-left: ${vwTablet(-144)};
    z-index: 1;
    .desktop {
      display: inline;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(134)};
    padding-right: ${vwDesktop(80)};
    padding-bottom: ${vwDesktop(134)};
    padding-left: ${vwDesktop(332)};
    margin-left: ${vwDesktop(-240)};
    z-index: 1;
  }
`;

// const DesktopImage = styled(ParallaxImage)`
//   display: none;
//   @media (min-width: ${media.tablet}) {
//     display: block;
//     z-index: 2;
//   }
// `;

const DesktopVideo = styled.div`
  display: none;
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(120)} 0 0;
  }
  @media (min-width: ${media.tablet}) {
    display: block;
    z-index: 2;
    width: ${vwDesktop(660)};
    height: ${vwDesktop(604)};
    margin: ${vwDesktop(100)} 0 0;
  }
`;

const Stat = styled.p`
  font-size: ${vw(13)};
  font-weight: 300;
  line-height: 1.62;
  color: ${({ theme }) => theme.color.black};
  margin-bottom: ${vw(4)};
  &:last-of-type {
    margin-bottom: ${vw(32)};
  }
  span {
    font-weight: 500;
  }
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(14)};
    margin-bottom: ${vwTablet(12)};
    &:last-of-type {
      margin-bottom: ${vwTablet(32)};
    }
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(14)};
    margin-bottom: ${vwDesktop(12)};
    &:last-of-type {
      margin-bottom: ${vwDesktop(32)};
    }
  }
`;
