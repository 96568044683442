import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Button = props => {
  const { transparent, blue, to, href, submit, className } = props;

  const handleAs = () => {
    if (href) {
      return 'a';
    } else if (submit) {
      return 'button';
    } else {
      return Link;
    }
  };
  return (
    <Root
      transparent={transparent && transparent.toString()}
      blue={blue && blue.toString()}
      to={to}
      href={href}
      as={handleAs()}
      target={href ? '_blank' : ''}
      type={submit && 'submit'}
      style={{ width: '-moz-fit-content' }}
      className={className}
    >
      <span>{props.children}</span>
      {href && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
        >
          <path
            fillRule="evenodd"
            d="M5.186 1.628v1.595h-3.59v7.181h7.18v-3.59h1.596V12H0V1.628h5.186zM12 0v5.186h-1.596V2.723l-4.3 4.302-1.129-1.129 4.301-4.301H6.814V0H12z"
          />
        </svg>
      )}
    </Root>
  );
};

Button.propTypes = {
  transparent: PropTypes.bool,
  blue: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  submit: PropTypes.bool,
};

export default Button;

const Root = styled(Link)`
  background-color: ${props =>
    props.transparent === 'true' ? 'transparent' : 'white'};
  padding: ${vw(9)} ${vw(40)} ${vw(13)};
  font-size: ${vw(16)};
  font-weight: 400;
  line-height: 1.63;
  color: ${props => {
    if (props.blue) {
      return props.theme.color.blue;
    } else if (props.transparent) {
      return 'white';
    } else {
      return props.theme.color.black;
    }
  }};
  border: 1px solid
    ${props =>
      props.blue === 'true' ? props.theme.color.blue : props.theme.color.white};
  border-bottom: 0;
  position: relative;
  display: block;
  width: fit-content;
  width: -moz-fit-content;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px);
    height: ${vw(4)};
    background-color: ${props => {
      if (props.blue === 'true') {
        return props.theme.color.blue;
      } else if (props.transparent === 'true') {
        return 'white';
      } else {
        return props.theme.color.black;
      }
    }};
  }
  svg {
    margin-left: ${vw(8)};
    fill: #0096d7;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(9)} ${vwTablet(40)} ${vwTablet(13)};
    font-size: ${vwTablet(16)};
    &::after {
      height: ${vwTablet(4)};
    }
    svg {
      margin-left: ${vwTablet(8)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(9)} ${vwDesktop(40)} ${vwDesktop(13)};
    font-size: ${vwDesktop(16)};
    transition: 0.3s ease-in-out;
    span {
      position: relative;
      z-index: 2;
    }
    &::after {
      height: calc(100% + 1px);
      max-height: ${vwDesktop(4)};
      transition: 0.3s ease-in-out;
      z-index: 1;
    }
    &:hover {
      color: ${props =>
        props.transparent === 'true' && !props.blue
          ? props.theme.color.blue
          : 'white'};
    }
    &:hover::after {
      max-height: calc(100% + 1px);
    }
    &:hover svg {
      fill: white;
    }
    svg {
      margin-left: ${vwDesktop(8)};
      transition: 0.3s ease-in-out;
      fill: #0096d7;
      z-index: 2;
      position: relative;
    }
  }
`;
