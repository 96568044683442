import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ImageSection = props => {
  const { title, text, image, rightImage } = props;
  return (
    <Root rightImage={rightImage}>
      <Parallax y={['80px', '-30px']}>
        <ParallaxImage src={image} alt={title} className="desktop-image" />
      </Parallax>
      <Section rightImage={rightImage}>
        <h3>{title}</h3>
        <img src={image} alt={title} className="mobile-image" />
        <p className="body-1">{text}</p>
      </Section>
    </Root>
  );
};

export default ImageSection;

const Root = styled.div`
  color: ${({ theme }) => theme.color.black};
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${vwTablet(60)};
    .desktop-image {
      display: block;
      width: ${vwTablet(312)};
      height: ${vwTablet(257)};
      object-fit: cover;
      margin-right: ${props => (props.rightImage ? 0 : vwTablet(-88))};
      margin-left: ${props => (props.rightImage ? vwTablet(-88) : 0)};
      z-index: 2;
      order: ${props => (props.rightImage ? 2 : 1)};
    }
    .parallax-outer {
      z-index: 2;
      order: ${props => (props.rightImage ? 2 : 1)};
    }
    p {
      margin-top: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    .desktop-image {
      width: ${vwDesktop(486)};
      height: ${vwDesktop(400)};
      margin-right: ${props => (props.rightImage ? 0 : vwDesktop(-180))};
      margin-left: ${props => (props.rightImage ? vwDesktop(-180) : 0)};
    }
    p {
      margin-top: ${vwDesktop(24)};
    }
  }
`;

const Section = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  margin: ${vw(60)} 0;
  .mobile-image {
    width: 100%;
    height: ${vw(230)};
    object-fit: cover;
    margin: ${vw(8)} 0;
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(90)};
    padding-right: ${props =>
      props.rightImage ? vwTablet(128) : vwTablet(40)};
    padding-bottom: ${vwTablet(90)};
    padding-left: ${props => (props.rightImage ? vwTablet(40) : vwTablet(128))};
    margin: ${vwTablet(80)} 0 ${vwTablet(80)};
    order: ${props => (props.rightImage ? 1 : 2)};
    .mobile-image {
      display: none;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(120)};
    padding-right: ${props =>
      props.rightImage ? vwDesktop(240) : vwDesktop(60)};
    padding-bottom: ${vwDesktop(120)};
    padding-left: ${props =>
      props.rightImage ? vwDesktop(60) : vwDesktop(240)};
    margin: ${vwDesktop(100)} 0 ${vwDesktop(80)};
  }
`;
