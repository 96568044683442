import React from 'react';
import { connect } from 'react-redux';

import PageLayout from 'components/PageLayout/PageLayout';
import Education from './sections/Education';
import Community from './sections/Community';
import AffordableHousing from './sections/AffordableHousing';
import NonprofitHousing from './sections/NonprofitHousing';

const sections = [
  {
    title: 'Education',
    section: <Education />,
  },
  {
    title: 'Community',
    section: <Community />,
  },
  {
    title: 'Affordable Housing',
    section: <AffordableHousing />,
  },
  {
    title: 'Non-profit Housing',
    section: <NonprofitHousing />,
  },
];

const Philanthropy = props => {
  console.log(props);
  return (
    <PageLayout
      title="Philanthropy"
      description={
        <>
          From sponsoring local initiatives to building schools and providing
          quality education, {props.browser.is.desktop && <br />}we believe that
          giving back is one of the most meaningful ways to help create a better
          future.
        </>
      }
      hero={require('src/assets/images/philanthropy/philanthropy-hero.jpg')}
      sections={sections}
      larger
    />
  );
};

const mapStateToProps = state => ({
  browser: state.browser,
});

export default connect(mapStateToProps, {})(Philanthropy);
