import React from 'react';
import styled from 'styled-components';

import Quote from 'components/Quote';
import Wrapper from 'components/PageLayout/elements/Wrapper';
import Button from 'components/Button';
import ImageSection from 'components/PageLayout/elements/ImageSection';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const History = () => {
  return (
    <Root>
      <Wrapper>
        <h4>History</h4>
        <h3>Daljit's Story</h3>
        <p className="body-1">
          Born and raised in the small village of Rachian, Ludhiana, India,
          Daljit Thind originally dreamt of becoming a pharmacist. But soon
          after graduating from Rohtak University, he got married and began
          dreaming of something bigger for his new family’s future.
          <br />
          <br />
          In 1993, he and his wife immigrated to Canada to begin a new life in a
          new country, full of new opportunities. What he didn’t expect was the
          difficulties he would face with a slow economy in the early 1990’s.
          Unfazed, and with an eye to the future, he persevered with odd jobs as
          a labourer in construction. Little did he know, these early
          experiences would prove to be the foundation in which he would build
          one of Vancouver’s leading development companies.
        </p>
        <Quote text="Daljit realized that real estate development was his true passion, and so, with renewed energy and focus, he took the next step and has never looked back." />
      </Wrapper>
      <ImageSection
        image={require('src/assets/images/about/Daljit-Speach-Contruction.jpg')}
        title="The First Home"
        text="It all began in 1996 when Daljit developed his first property – a
        single-family home. Although it had its challenges, it was also full
        of vital learning moments that he took to heart. Most importantly,
        Daljit realized that real estate development was his true passion,
        and so, with renewed energy and focus, he took the next step and has
        never looked back."
      />
      <Wrapper>
        <h3>Building Communities</h3>
        <p className="description body-1">
          To date, we have built over a thousand homes throughout Metro
          Vancouver with thousands more planned in the next few years.
          <br />
          <br />
          From new condo and townhouse developments, to single-family homes and
          custom builds, we specialize in a wide range of projects, putting our
          distinctive stamp on everything we do. Each one of our homes is built
          with an unmatched attention to design—from inception to creation,
          every detail is accounted for. Our homes endure the test of time and
          play a large role in enhancing the communities in which they are
          built.
        </p>
        <Button to="/communities" blue transparent>
          View Communities
        </Button>
        <img
          src={require('src/assets/images/about/starling.jpg')}
          alt="Building Communities"
          className="building"
        />
      </Wrapper>
    </Root>
  );
};

export default History;

const Root = styled.div`
  margin-bottom: ${vw(80)};
  .building {
    width: 100%;
    height: ${vw(159)};
    object-fit: cover;
    margin-top: ${vw(32)};
  }
  .description {
    margin: ${vw(24)} 0;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(140)};
    p {
      max-width: ${vwTablet(536)};
    }
    .description {
      margin: ${vwTablet(24)} 0 ${vwTablet(32)};
    }
    .building {
      height: ${vwTablet(240)};
      margin-top: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
    p {
      max-width: ${vwDesktop(588)};
    }
    .description {
      margin: ${vwDesktop(24)} 0 ${vwDesktop(32)};
    }
    .building {
      height: ${vwDesktop(300)};
      margin-top: ${vwDesktop(40)};
    }
  }
`;
