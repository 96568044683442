import React from 'react';

export default [
  {
    title: 'Communities',
    description: (
      <>
        <span> Building quality </span>
        homes and
        <span> creating value </span>
        for you and your family.
      </>
    ),
    to: '/communities',
    buttonText: 'View Communities',
    image: require('src/assets/images/home/lumina-masterplan.jpg'),
  },
  {
    title: 'Construction',
    description: (
      <>
        Construction quality and service <span>you can count on.</span>
      </>
    ),
    to: '/construction',
    buttonText: 'Learn More',
    image: require('src/assets/images/home/thind-construction.jpg'),
    panLeft: true,
  },
  {
    title: 'Philanthropy',
    description: (
      <>
        Creating a better future by <span> giving back </span> to our
        communities.
      </>
    ),
    to: '/philanthropy',
    buttonText: 'Learn More',
    image: require('src/assets/images/home/philanthropy-hero.jpg'),
    panTop: true,
  },
  {
    title: 'Entertainment',
    description: (
      <>
        <span> Entertaining audiences </span> from all around the world.
      </>
    ),
    to: '/entertainment',
    buttonText: 'Learn More',
    image: require('src/assets/images/home/entertainment-thind.jpg'),
    zoomIn: true,
  },
];
