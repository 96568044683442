import React from 'react';

import PageLayout from 'components/PageLayout/PageLayout';
import Info from './sections/Info';
import Contact from './sections/Contact';

const sections = [
  {
    title: 'Communities',
    section: <Info />,
  },
  {
    title: 'Homeowner Care',
    section: <Contact />,
  },
];

const HomeownerCare = () => {
  return (
    <PageLayout
      title="Homeowner Care"
      description="To ensure that you are always satisfied with your home in every way, our Customer Care is committed to providing you with an unmatched level of service."
      hero={require('src/assets/images/contact/Thind-Homeowners.jpg')}
      sections={sections}
    />
  );
};

export default HomeownerCare;
