import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const SectionTwo = () => {
  return (
    <Root>
      <h4>services</h4>
      <h3>Turnkey Solutions</h3>
      <p className="body-1">
        <ul>
          <li>Project feasibility analysis</li>
          <li>Coordination of design consultants</li>
          <li>
            Rezoning applications – including public hearing &amp; design panel
            approvals
          </li>
          <li>Development &amp; building permit approvals</li>
          <li>Estimating and value engineering</li>
          <li>Tendering and procurement</li>
          <li>Contract administration</li>
          <li>Scheduling</li>
          <li>Project accounting</li>
          <li>Quality control</li>
          <li>Safety management</li>
          <li>Site supervision</li>
          <li>Subcontractor management</li>
          <li>Status reporting – schedule &amp; financial</li>
          <li>After marketing warranty division</li>
        </ul>
      </p>
      <img
        src={require('src/assets/images/construction/construction-hardhelmets.jpg')}
        alt="Hard Helmets"
      />
    </Root>
  );
};

export default SectionTwo;

const Root = styled.div`
  padding: 0 ${vw(20)};
  margin-bottom: ${vw(80)};
  img {
    width: 100%;
    height: ${vw(160)};
    object-fit: cover;
    margin-top: ${vw(32)};
  }
  li {
    list-style-type: none;
    display: flex;
    &::before {
      content: '∙';
      margin-right: 8px;
      font-weight: normal;
    }
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
    margin-bottom: ${vwTablet(140)};
    img {
      height: ${vwTablet(240)};
      margin-top: ${vwTablet(40)};
    }
    p {
      max-width: ${vwTablet(534)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    margin-bottom: ${vwDesktop(140)};
    img {
      height: ${vwDesktop(300)};
      margin-top: ${vwDesktop(40)};
    }
    p {
      max-width: ${vwDesktop(588)};
    }
  }
`;
