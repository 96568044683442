import React from 'react';
import styled from 'styled-components';

import Quote from 'components/Quote';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Education = () => {
  return (
    <Root>
      <h4>Education</h4>
      <h3>A Foundation to Build On</h3>
      <p className="body-1">
        Thind was founded on the desire to create a better future. Today, that
        dream has come true for thousands of homeowners. After two decades of
        building homes, we believe that there’s more than one way to build a
        community. By sponsoring local children’s clubs and homeless outreach
        programs, building schools, and giving over a thousand students the
        opportunity of education, giving back is one of the most meaningful ways
        we help build communities around the world.
        <br />
        <br />
        Education is one of the foundations of Thind’s philanthropy. Daljit
        Thind is a chairman of Canadian Point Grey School, the first British
        Columbia offshore school in Punjab India that gives opportunities for
        students to continue their post-secondary education in Canada.
      </p>
      <Quote
        text="“It gives me great pleasure to welcome you to Canadian Point Grey School. We have worked very hard to bring together the top academic professionals to create a world-class curriculum to give our youth a head-start and a strong
foundation to build on. This school and what it represents is very close to my heart as I immigrated to Canada with very little except for the education and work ethic my parents had provided me. My hope is that this school will also provide the opportunities I have found in Canada.”"
      />
      <Info>
        <h4 className="black">— Daljit Thind, Chairman</h4>
        <img
          src={require('src/assets/images/philanthropy/canadian-point-grey-school.png')}
          alt="Canadian Point Grey School"
        />
      </Info>
    </Root>
  );
};

export default Education;

const Root = styled.div`
  padding: 0 ${vw(20)} ${vw(80)};
  .black {
    color: ${({ theme }) => theme.color.black};
    margin: ${vw(24)} 0 ${vw(16)};
  }
  img {
    width: ${vw(180)};
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)} ${vwTablet(140)};
    .black {
      margin: ${vwTablet(32)} 0 ${vwTablet(16)};
    }
    img {
      width: ${vwTablet(200)};
    }
    > p {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 0 ${vwDesktop(140)};
    .black {
      margin: ${vwDesktop(32)} 0 ${vwDesktop(16)};
    }
    img {
      width: ${vwDesktop(282)};
    }
    > p {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Info = styled.div`
  @media (min-width: ${media.tablet}) {
    margin-left: ${vwTablet(84)};
  }
  @media (min-width: ${media.desktop}) {
    margin-left: ${vwDesktop(102)};
  }
`;
