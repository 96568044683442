import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Button from 'components/Button';
import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const HomeownerCare = () => {
  return (
    <Root>
      <Container>
        <h2>Homeowner Care</h2>
        <img
          src={require('src/assets/images/contact/homeowner-care.jpg')}
          alt="Homeowner Care"
          className="mobile-image"
        />
        <p className="body-1">
          From the day we start construction to years after you’ve moved in, we
          treat every home we build like it was our own. To ensure that you are
          always satisfied with your home in every way, our Customer Care is
          committed to providing you with an unmatched level of service.
        </p>
        <Button to="/homeownercare" blue transparent>
          Learn More
        </Button>
      </Container>
      <Parallax y={['80px', '-30px']}>
        <ParallaxImage
          src={require('src/assets/images/contact/homeowner-care.jpg')}
          alt="Homeowner Care"
          className="desktop-image"
        />
      </Parallax>
    </Root>
  );
};

export default HomeownerCare;

const Root = styled.div`
  img {
    width: 100%;
    height: ${vw(200)};
    object-fit: cover;
    margin: ${vw(32)} 0;
  }
  p {
    margin-bottom: ${vw(32)};
  }
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    align-items: center;
    .mobile-image {
      display: none;
    }
    .desktop-image {
      display: block;
      margin-left: ${vwTablet(-144)};
      margin-top: ${vwTablet(20)};
    }
    img {
      width: ${vwTablet(312)};
      height: ${vwTablet(212)};
      object-fit: cover;
      margin: 0;
    }
    p {
      margin: ${vwTablet(32)} 0;
      max-width: ${vwTablet(256)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .desktop-image {
      margin-left: ${vwDesktop(-179)};
      margin-top: 0;
    }
    img {
      width: ${vwDesktop(588)};
      height: ${vwDesktop(400)};
    }
    p {
      margin: ${vwDesktop(32)} 0;
      max-width: ${vwDesktop(442)};
    }
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(80)} ${vwTablet(40)};
    width: ${vwTablet(480)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(151)} ${vwDesktop(90)};
    width: ${vwDesktop(791)};
  }
`;
