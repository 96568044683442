export default {
  color: {
    blue: '#0096d7',
    white: '#ffffff',
    black: '#222222',
    grey1: '#3c3c3b',
    grey2: '#676767',
    grey3: '#888888',
    grey4: '#aaaaaa',
    grey5: '#dadada',
    grey6: '#f5f5f5',
  },
  font: 'aktiv-grotesk, sans-serif',
};
