import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const NonprofitHousing = () => {
  return (
    <Root>
      <Wrapper>
        <h4>Non-Profit Housing</h4>
        <h3>Open Door Housing Society</h3>
        <p className="body-1">
          Established in 2018 by Daljit Thind, the Open Door Housing Society is
          dedicated to expanding the vision and impact of non-profit housing.
          Building on his partnership with New Vista Society and BC Housing on
          the Sussex Development, Daljit has positioned the Open Door Housing
          Society as a leader in innovation in the non-profit housing sector. By
          linking education and employment opportunities to affordable housing
          strives to change lives and communities for the better.
        </p>
      </Wrapper>
      <Desktop>
        <Parallax y={['80px', '-30px']}>
          <ParallaxImage
            src={require('src/assets/images/philanthropy/Justin-Trudeau-Daljit-Thind.jpg')}
            alt="Justin Trudeau Daljit Thind"
            className="desktop-image"
          />
        </Parallax>
        <Success>
          <h3>Reinventing the Meaning of Success</h3>
          <img
            src={require('src/assets/images/philanthropy/Justin-Trudeau-Daljit-Thind.jpg')}
            alt="Justin Trudeau Daljit Thind"
            className="mobile-image"
          />
          <p className="body-1">
            From an industry leading real estate developer, to entrepreneur and
            philanthropist, Daljit Thind continues to reinvent the meaning of
            success. By focusing on the future and giving back to communities,
            Thind is creating a brighter future for everyone.
          </p>
        </Success>
      </Desktop>
    </Root>
  );
};

export default NonprofitHousing;

const Root = styled.div`
  padding: 0 0 ${vw(80)};
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 0 ${vwTablet(140)};
    p {
      max-width: ${vwTablet(534)};
    }
    .mobile-image {
      display: none;
    }
    .desktop-image {
      display: block;
      width: ${vwTablet(312)};
      height: ${vwTablet(257)};
      object-fit: cover;
      margin-right: ${vwTablet(-88)};
      z-index: 2;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 0 ${vwDesktop(140)};
    p {
      max-width: ${vwDesktop(588)};
    }
    .desktop-image {
      width: ${vwDesktop(486)};
      height: ${vwDesktop(360)};
      margin-right: ${vwDesktop(-180)};
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
  }
`;

const Success = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  color: ${({ theme }) => theme.color.black};
  img {
    width: 100%;
    margin: ${vw(8)} 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(90)} ${vwTablet(40)} ${vwTablet(90)} ${vwTablet(128)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(121)};
    padding-right: ${vwDesktop(60)};
    padding-bottom: ${vwDesktop(121)};
    padding-left: ${vwDesktop(240)};
    max-width: ${vwDesktop(588)};
  }
`;

const Desktop = styled.div`
  margin-top: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    display: flex;
    align-items: center;
    padding: 0 ${vwTablet(60)};
    margin-top: ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: ${vwDesktop(80)};
  }
`;
