export default [
  {
    title: 'Lumina Alpha',
    subTitle: 'Burnaby',
    image: require('src/assets/images/properties/lumina-community.jpg'),
    to: '/communities/lumina-alpha',
  },
  {
    title: 'Lumina Starling',
    subTitle: 'Burnaby',
    image: require('src/assets/images/properties/lumina-starling.jpg'),
    to: '/communities/lumina-alpha',
  },
  {
    title: 'Lumina Waterfall',
    subTitle: 'Burnaby',
    image: require('src/assets/images/properties/lumina-waterfall.jpg'),
    to: '/communities/lumina-waterfall',
  },
  {
    title: 'Pixel',
    subTitle: 'Burnaby',
    image: require('src/assets/images/properties/pixel-community.jpg'),
    to: '/communities/pixel',
  },
  {
    title: '1st & Renfrew',
    subTitle: 'Vancouver',
    image: require('src/assets/images/properties/1st-and-renfrew-community.jpg'),
    to: '/communities/1st-and-renfrew',
  },
  {
    title: 'All Communities',
    viewall: true,
    image: require('src/assets/images/properties/thind-communities.jpg'),
    to: '/communities',
  },
];
