import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Wrapper = props => {
  return <Root>{props.children}</Root>;
};

export default Wrapper;

const Root = styled.div`
  padding: 0 ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
  }
`;
