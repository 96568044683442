import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Button from 'components/Button';
import Wrapper from 'components/PageLayout/elements/Wrapper';
import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const films = [
  {
    image: require('src/assets/images/entertainment/munda-faridkotia.jpg'),
    title: 'Munda Faridkotia (2019)',
  },
  {
    image: require('src/assets/images/entertainment/saab-bahadar.jpg'),
    title: 'Saab Bhahadar (2017)',
  },
  {
    image: require('src/assets/images/entertainment/Sardaarji2.jpg'),
    title: 'Sardaarji 2 (2016)',
  },
  {
    image: require('src/assets/images/entertainment/sardaaji.jpg'),
    title: 'Sardaarji (2015)',
  },
  {
    image: require('src/assets/images/entertainment/punjab-1984.jpg'),
    title: 'Punjab 1984 (2014)',
  },
];

const Overview = () => {
  return (
    <Root>
      <Wrapper>
        <h4>Overview</h4>
        <h3>An Icon in the Industry</h3>
        <p className="body-1 description">
          Recognized in the Bollywood Film Industry and the producer of several
          films, Daljit was instrumental in bringing the Times of India TOlFA
          Awards to Vancouver and is active in the creation, production, and
          distribution of unique Indian films locally and internationally.
        </p>
      </Wrapper>
      <Desktop>
        <Section>
          <img
            src={require('src/assets/images/entertainment/dalmora-logo.png')}
            alt="Dalmora Entertainment"
            className="logo"
          />
          <img
            src={require('src/assets/images/entertainment/film-camera.jpg')}
            alt="Film Camera"
            className="mobile-image"
          />
          <p className="body-1">
            Dalmora Entertainment is a Vancouver-based film production company
            focused on creating unique Indian films. They combine the best of
            Eastern and Western film, producing a crossover cinema that appeals
            to the Bollywood audience in India as well as audiences around the
            world.
          </p>
          <Button blue transparent href="https://dalmora.com/">
            Visit Website
          </Button>
        </Section>
        <Parallax y={['80px', '-30px']}>
          <ParallaxImage
            src={require('src/assets/images/entertainment/film-camera.jpg')}
            alt="Film Camera"
            className="desktop-image"
          />
        </Parallax>
      </Desktop>
      <Wrapper>
        <h3>Featured Films</h3>
        <Films>
          {films.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.title} />
              <p className="body-2">{item.title}</p>
            </div>
          ))}
        </Films>
      </Wrapper>
    </Root>
  );
};

export default Overview;

const Root = styled.div`
  color: ${({ theme }) => theme.color.black};
  @media (min-width: ${media.tablet}) {
    .description {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    .description {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Section = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  margin: ${vw(60)} 0;
  .logo {
    width: ${vw(240)};
  }
  .mobile-image {
    width: 100%;
    height: ${vw(230)};
    object-fit: cover;
    margin: ${vw(32)} 0;
  }
  p {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(90)} ${vwTablet(128)} ${vwTablet(90)} ${vwTablet(40)};
    margin: ${vwTablet(80)} 0;
    .logo {
      width: ${vwTablet(240)};
    }
    .mobile-image {
      display: none;
    }
    p {
      margin: ${vwTablet(32)} 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(123)};
    padding-right: ${vwDesktop(162)};
    padding-bottom: ${vwDesktop(123)};
    padding-left: ${vwDesktop(60)};
    margin: ${vwDesktop(80)} 0;
    .logo {
      width: ${vwDesktop(240)};
    }
    p {
      margin: ${vwDesktop(32)} 0;
    }
  }
`;

const Desktop = styled.div`
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    .desktop-image {
      display: block;
      width: ${vwTablet(311)};
      height: ${vwTablet(300)};
      object-fit: cover;
      margin-left: ${vwTablet(-88)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    .desktop-image {
      width: ${vwDesktop(384)};
      height: ${vwDesktop(410)};
      margin-left: ${vwDesktop(-102)};
    }
  }
`;

const Films = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${vw(32)};
  > div {
    width: ${vw(128)};
    text-align: center;
    margin-bottom: ${vw(24)};
  }
  img {
    width: auto;
    margin: 0 auto ${vw(4)};
    height: ${vw(171)};
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(40)};
    justify-content: flex-start;
    > div {
      width: ${vwTablet(200)};
      text-align: center;
      margin-bottom: ${vwTablet(40)};
      margin-right: ${vwTablet(24)};
    }
    > div:nth-of-type(3n) {
      margin-right: 0;
    }
    img {
      height: ${vwTablet(267)};
      margin: 0 auto ${vwTablet(12)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(40)};
    > div {
      width: ${vwDesktop(282)};
      margin-bottom: ${vwDesktop(40)};
      margin-right: ${vwDesktop(24)};
    }
    img {
      height: ${vwDesktop(376)};
      margin: 0 auto ${vwDesktop(12)};
    }
  }
`;
