import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SubButton from 'components/SubButton';
import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Navigation = props => {
  const { handleNext, handlePrev, nextTitle, prevTitle, isGrey } = props;
  return (
    <Root isGrey={isGrey}>
      <Wrapper>
        <Prev onClick={handlePrev}>
          <img
            src={require('src/assets/images/icons/arrow-left.svg')}
            alt="Previous"
          />
          <div>
            <p className="body-1">{prevTitle}</p>
            <h4>
              Previous <span> Community</span>
            </h4>
          </div>
        </Prev>
        <div className="desktop">
          <Button to="/communities" blue transparent>
            Back to Communities
          </Button>
        </div>
        <Next onClick={handleNext}>
          <div>
            <p className="body-1">{nextTitle}</p>
            <h4>
              Next <span> Community</span>
            </h4>
          </div>
          <img
            src={require('src/assets/images/icons/arrow-right.svg')}
            alt="Next"
          />
        </Next>
      </Wrapper>
      <div className="mobile">
        <SubButton to="/communities" blue>
          Back to Communities
        </SubButton>
      </div>
      <div className="tablet">
        <Button to="/communities" blue>
          Back to Communities
        </Button>
      </div>
    </Root>
  );
};

Navigation.propTypes = {
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  nextTitle: PropTypes.string,
  prevTitle: PropTypes.string,
};

export default Navigation;

const Root = styled.div`
  padding: 0 ${vw(20)} ${vw(80)};
  margin-top: ${vw(40)};
  .desktop,
  .tablet {
    display: none;
  }
  img {
    width: ${vw(9)};
  }
  h4 {
    color: ${({ theme }) => theme.color.black};
  }
  p {
    color: ${({ theme }) => theme.color.black};
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)} ${vwTablet(140)};
    margin-top: ${vwTablet(60)};
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
    img {
      width: ${vwTablet(18)};
    }
    h4 {
      display: flex;
      color: ${({ theme }) => theme.color.grey3};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${props => (props.isGrey ? vwDesktop(80) : vwDesktop(150))}
      ${vwDesktop(120)} ${vwDesktop(140)};
    margin-top: 0;
    background-color: ${props =>
      props.isGrey ? props.theme.color.grey6 : 'white'};
    img {
      width: ${vwDesktop(18)};
    }
    .tablet {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${vw(40)};
  p,
  span {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    padding-bottom: ${vwTablet(60)};
    p,
    span {
      display: block;
    }
    span {
      margin-left: ${vwTablet(4)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-bottom: 0;
    p,
    span {
      display: block;
    }
    span {
      margin-left: ${vwDesktop(4)};
    }
  }
`;

const Prev = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: ${vw(8)};
  }
  @media (min-width: ${media.tablet}) {
    img {
      margin-right: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    cursor: pointer;
    img {
      margin-right: ${vwDesktop(24)};
    }
  }
`;

const Next = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: ${vw(8)};
  }
  @media (min-width: ${media.tablet}) {
    text-align: right;
    img {
      margin-left: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    cursor: pointer;
    img {
      margin-left: ${vwDesktop(24)};
    }
  }
`;
