import React from 'react';

import PageLayout from 'components/PageLayout/PageLayout';
import Overview from './sections/Overview';
import SectionTwo from './sections/SectionTwo';

const sections = [
  {
    title: 'Overview',
    section: <Overview />,
  },
  {
    title: 'Services',
    section: <SectionTwo />,
  },
];

const Construction = () => {
  return (
    <PageLayout
      title="Construction"
      description="From the first single-family homes we built over 20 years ago, to the highrise towers we’ve completed over the past few years, Thind Construction has consistently delivered a level of quality and service you can count on."
      hero={require('src/assets/images/construction/thind-construction.jpg')}
      sections={sections}
    />
  );
};

export default Construction;
