import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

import Hamburger from './elements/Hamburger';
import MobileMenu from './elements/MobileMenu';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { menu, subMenu } from 'src/data/menu';

const Header = props => {
  const [isOpen, setIsOpen] = useState(false);

  const [isTransparent, setIsTransparent] = useState(false);

  const [isScrollingDown, setIsScrollingDown] = useState(false);

  useEffect(() => {
    if (
      props.location.pathname === '/' ||
      (props.location.pathname.includes('/communities/') &&
        props.location.pathname.length > 13)
    ) {
      setIsTransparent(true);
      setIsScrollingDown(false);
    } else {
      setIsTransparent(false);
      setIsScrollingDown(false);
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [props.location.pathname]);

  let lastPosition = 0;

  const handleScroll = e => {
    // checks to set it to transparent at the top of the screen if on the home page
    if (e.target.scrollingElement.scrollTop > 20) {
      setIsTransparent(false);
    } else if (
      e.target.scrollingElement.scrollTop < 20 &&
      (props.location.pathname === '/' ||
        (props.location.pathname.includes('/communities/') &&
          props.location.pathname.length > 13))
    ) {
      setIsTransparent(true);
    }

    // checks to see if user is scrolling up or down to hide the header or not
    let scrollPos = window.pageYOffset;

    if (scrollPos > lastPosition && e.target.scrollingElement.scrollTop > 100) {
      setIsScrollingDown(true);
    } else if (scrollPos < lastPosition) {
      setIsScrollingDown(false);
    }
    lastPosition = scrollPos;
  };

  const handleMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const closeMenu = () => setIsOpen(false);

  return (
    <>
      <Root isTransparent={isTransparent} isScrolling={isScrollingDown}>
        <Left isTransparent={isTransparent}>
          <Link to="/" onClick={closeMenu}>
            <img
              src={
                !isTransparent || isOpen
                  ? require('src/assets/images/logos/colour.svg')
                  : require('src/assets/images/logos/white.svg')
              }
              alt="Thind"
            />
          </Link>
          {menu.map((item, index) => (
            <DesktopItem
              key={item.title}
              className="body-1"
              isTransparent={isTransparent}
              active={props.location.pathname.includes(item.to)}
            >
              <p className="body-1">
                <Link to={item.to}>{item.title}</Link>
              </p>
              <div>
                <span />
                <span />
                <span />
              </div>
            </DesktopItem>
          ))}
        </Left>
        <Right isTransparent={isTransparent}>
          <Hamburger
            isOpen={isOpen}
            isTransparent={isTransparent}
            handleClick={handleMenu}
          />
          {subMenu.map((item, index) => (
            <DesktopItem
              key={item.title}
              isHidden={item.hideOnDesktop}
              className="body-1"
              isTransparent={isTransparent}
              active={props.location.pathname.includes(item.to)}
            >
              <p className="body-1">
                {' '}
                <Link to={item.to}>{item.title}</Link>
              </p>
              <div>
                <span />
                <span />
                <span />
              </div>
            </DesktopItem>
          ))}
        </Right>
      </Root>
      <MobileMenu
        isOpen={isOpen}
        handleMenu={handleMenu}
        location={props.location.pathname}
      />
    </>
  );
};

export default withRouter(Header);

const Root = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
  padding: ${vw(20)};
  background-color: ${props => (props.isTransparent ? 'transparent' : 'white')};
  box-shadow: ${props =>
    props.isTransparent ? 'none' : '0 0 10px 0 rgba(0, 0, 0, 0.08)'};
  top: ${props => (props.isScrolling ? vw(-80) : 0)};
  transition: background-color 0.3s ease, top 0.5s ease-in-out;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(30)} ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(20)} ${vwDesktop(120)};
  }
`;

const Left = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    align-items: flex-end;
    a {
      max-height: ${vwDesktop(34)};
    }
  }
  img {
    width: ${vw(130)};
    height: ${vw(34)};
    @media (min-width: ${media.tablet}) {
      width: ${vwTablet(130)};
      height: ${vwTablet(34)};
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(130)};
      height: ${vwDesktop(34)};
    }
  }
`;

const Right = styled.div`
  width: ${vw(24)};
  height: ${vw(20)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(24)};
    height: ${vwTablet(20)};
  }
  @media (min-width: ${media.desktop}) {
    width: auto;
    height: ${vwDesktop(34)};
    display: flex;
    align-items: flex-end;
  }
`;

const DesktopItem = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: ${props => (props.isHidden ? 'none' : 'block')};
    margin-left: ${vwDesktop(32)};
    position: relative;
    width: fit-content;
    overflow-x: hidden;
    cursor: pointer;
    a {
      color: ${props => {
        if (props.isTransparent) {
          return 'white';
        } else if (props.active) {
          return props.theme.color.blue;
        } else {
          return props.theme.color.black;
        }
      }};
      transition: 0.3s ease;
      max-height: ${vwDesktop(34)};
    }
    > div {
      width: 100%;
      position: absolute;
      bottom: 0;
      /* left: ${props => (props.active ? '-300%' : '-400%')}; */
      opacity: ${props => (props.active ? 1 : 0)};
      transition: 0.4s ease-in-out;
      display: flex;
      span {
        width: 100%;
        height: 1px;
        background-color: ${props =>
          props.isTransparent ? 'white' : props.theme.color.blue};
      }
      span:nth-of-type(2) {
        /* background-color: transparent; */
      }
    }
    &:hover {
      > div {
        /* left: 0%; */
        opacity: 1;
      }
    }
  }
`;
