import React from 'react';
import styled from 'styled-components';

import Properties from './sections/Properties';
import HomeownerCare from 'components/HomeownerCare/HomeownerCare';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Communities = () => {
  return (
    <Root>
      <Container>
        <h1>Communities</h1>
        <p className="body-large">
          Discover our portfolio of single-family homes, townhomes, and condos
          in Vancouver, Burnaby, and the Lower Mainland.
        </p>
        <Properties />
      </Container>
      <HomeownerCare />
    </Root>
  );
};

export default Communities;

const Root = styled.div`
  padding-top: ${vw(154)};
  h1 {
    margin-bottom: ${vw(16)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(234)} ${vwTablet(60)} ${vwTablet(140)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    .body-large {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(214)} ${vwDesktop(120)} ${vwDesktop(140)};
    h1 {
      margin-bottom: ${vwDesktop(24)};
    }
    .body-large {
      max-width: ${vwDesktop(792)};
    }
  }
`;

const Container = styled.div`
  padding: 0 ${vw(20)};
  margin-bottom: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    padding: 0;
    margin-bottom: ${vwTablet(140)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
  }
`;
