import React from 'react';
import styled from 'styled-components';

import Info from './sections/Info';
import Form from './sections/Form';
import HomeownerCare from 'components/HomeownerCare/HomeownerCare';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Contact = () => {
  return (
    <Root>
      <h1>Contact Us</h1>
      <Container>
        <Info />
        <Form />
      </Container>
      <HomeownerCare />
    </Root>
  );
};

export default Contact;

const Root = styled.div`
  padding-top: ${vw(154)};
  color: ${({ theme }) => theme.color.black};
  h1 {
    padding: 0 ${vw(20)} ${vw(80)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(234)} ${vwTablet(60)} ${vwTablet(140)};
    h1 {
      padding: 0 0 ${vwTablet(140)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(214)} ${vwDesktop(120)} ${vwDesktop(140)};
    h1 {
      padding: 0 0 ${vwDesktop(80)};
    }
  }
`;

const Container = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;
