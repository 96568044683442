import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const images = [
  {
    image: require('src/assets/images/philanthropy/childrens-hospital.png'),
    hallOfFame: false,
    nightOfMiracles: true,
  },
  {
    image: require('src/assets/images/philanthropy/pics.png'),
    hallOfFame: true,
  },
  {
    image: require('src/assets/images/philanthropy/surrey-memorial.png'),
    hallOfFame: false,
  },
  {
    image: require('src/assets/images/philanthropy/minerva.png'),
    hallOfFame: false,
  },
  {
    image: require('src/assets/images/philanthropy/cancer-foundation.png'),
    hallOfFame: false,
  },
  {
    image: require('src/assets/images/philanthropy/vancouver-foundation.png'),
    hallOfFame: false,
  },
  {
    image: require('src/assets/images/philanthropy/burnaby-hospital.png'),
    hallOfFame: false,
  },
];

const Community = () => {
  return (
    <Root>
      <h4>Community</h4>
      <h3>Giving Back</h3>
      <p className="body-1">
        Along with a focus on education, Thind is also actively involved in
        supporting a wide variety of local charitable initiatives by providing
        funding, resources, and support.
      </p>
      <Images>
        {images.map((item, index) => (
          <div key={index}>
            <img src={item.image} alt="" />
            {item.hallOfFame && <p className="body-2">Hall of Fame Donor</p>}
            {item.nightOfMiracles && (
              <p className="body-2">Night of Miracle's Donor</p>
            )}
          </div>
        ))}
      </Images>
    </Root>
  );
};

export default Community;

const Root = styled.div`
  padding: 0 ${vw(20)} ${vw(80)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)} ${vwTablet(140)};
    p {
      max-width: ${vwTablet(534)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 0 ${vwDesktop(140)};
    p {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Images = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${vw(40)} 0 0;
  > div {
    width: ${vw(128)};
    text-align: center;
    margin-bottom: ${vw(24)};
    img {
      width: 100%;
      height: auto;
    }
    p {
      font-size: ${vw(13)};
      line-height: 1.6;
    }
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(40)} 0 0;
    > div {
      width: ${vwTablet(180)};
      margin-bottom: ${vwTablet(40)};
      p {
        font-size: ${vwTablet(14)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(40)} ${vwDesktop(102)} 0 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr, 1fr;
    grid-gap: ${vwDesktop(24)};
    > div {
      width: ${vwDesktop(180)};
      margin-bottom: ${vwDesktop(40)};
      p {
        font-size: ${vwDesktop(14)};
      }
    }
  }
`;
