import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Textarea = props => {
  const { label, name, id, required } = props;
  return (
    <Root>
      <label htmlFor={id}>{label}</label>
      <textarea name={name} id={id} required={required} rows="5" />
    </Root>
  );
};

export default Textarea;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${vw(16)};
  textarea {
    width: 100%;
    background-color: ${({ theme }) => theme.color.grey6};
    border: none;
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    color: ${({ theme }) => theme.color.black};
    padding: ${vw(4)} ${vw(16)};
    transition: 0.3s ease;
    &:focus {
      outline: none;
      background-color: white;
      border: 1px solid ${({ theme }) => theme.color.blue};
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
    }
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(16)};
    textarea {
      font-size: ${vwTablet(16)};
      padding: ${vwTablet(4)} ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(16)};
    textarea {
      font-size: ${vwDesktop(16)};
      padding: ${vwDesktop(4)} ${vwDesktop(16)};
    }
  }
`;
