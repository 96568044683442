import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Whoweare = () => {
  return (
    <Root name="whoweare">
      <Container>
        <h2>Who We Are</h2>
        <p>
          For over 20 years, we have turned the dream of owning a home into a
          reality for thousands of families. With a reputation for quality,
          architectural innovation, contemporary style, customer service, and
          warranties you can count on, we are leading the future of Vancouver
          real estate.
        </p>
        <Button transparent to="/about">
          Learn More
        </Button>
      </Container>
    </Root>
  );
};

export default Whoweare;

const Root = styled.div`
  padding: ${vw(60)} 0 ${vw(164)};
  color: white;
  background-image: url(${require('src/assets/images/home/highline-night-mobile.jpg')});
  background-size: cover;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(140)} 0;
    height: 100vh;
    background-size: cover;
    background-position: 70% bottom;
    background-image: url(${require('src/assets/images/home/highline-night-desktop.jpg')});
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(140)} 0;
    background-size: cover;
    background-position: 70% bottom;
  }
  p {
    padding: ${vw(32)} 0;
    @media (min-width: ${media.tablet}) {
      padding: ${vwTablet(32)} 0;
      max-width: ${vwTablet(392)};
    }
    @media (min-width: ${media.desktop}) {
      padding: ${vwDesktop(32)} 0;
      max-width: ${vwDesktop(588)};
    }
  }
`;
