import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import mapStyles from 'src/data/mapStyles';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Map = props => {
  const { item } = props;

  const mapOptions = {
    zoomControl: false,
    fullscreenControl: false,
    styles: mapStyles,
  };

  return (
    <Root>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw' }}
        defaultCenter={{ lat: item.lat, lng: item.lng }}
        defaultZoom={15}
        options={mapOptions}
      >
        <Pin
          src={require('src/assets/images/icons/map-pin.svg')}
          alt={item.title}
          lat={item.lat}
          lng={item.lng}
        />
      </GoogleMapReact>
    </Root>
  );
};

export default Map;

const Root = styled.div`
  width: 100%;
  height: ${vw(260)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(260)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(320)};
  }
`;

const Pin = styled.img`
  width: ${vw(32)};
  height: ${vw(44)};
  transform: translate(-50%, -100%);
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(32)};
    height: ${vwTablet(44)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(32)};
    height: ${vwDesktop(44)};
  }
`;
