import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const SubButton = props => {
  const { to, white, handleClick } = props;
  return (
    <Root
      to={to}
      white={white && white.toString()}
      as={to ? Link : 'button'}
      onClick={handleClick}
      style={{ width: '-moz-fit-content' }}
    >
      {props.children}
      <div className="subbutton-line">
        <span />
        <span />
        <span />
      </div>
    </Root>
  );
};

export default SubButton;

const Root = styled(Link)`
  font-size: ${vw(16)};
  font-weight: normal;
  line-height: 1.63;
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  overflow: hidden;
  display: block;
  color: ${props =>
    props.white === 'true' ? 'white' : props.theme.color.blue};
  cursor: pointer;
  > div {
    width: 300%;
    position: absolute;
    bottom: 0;
    left: -200%;
    transition: 0.5s ease-in-out;
    display: flex;
    span {
      width: 100%;
      height: 1px;
      background-color: ${props =>
        props.white === 'true' ? 'white' : props.theme.color.blue};
    }
    span:nth-of-type(2) {
      background-color: transparent;
    }
  }
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(16)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(16)};
    /* &:hover {
      > div {
        left: 0;
      }
    } */
  }
`;
