import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'react-scroll';

import Button from 'components/Button';

import data from 'src/data/hero';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const [prevSlide, setPrevSlide] = useState(0);

  const sliderRef = React.createRef();

  const handleBeforeChange = (index, nextIndex) => {
    setPrevSlide(index);
    setActiveSlide(nextIndex);
  };

  const settings = {
    arrows: false,
    autplaySpeed: 4000,
    autoplay: true,
    fade: true,
    dots: false,
    infinite: true,
    pauseOnHover: false,
    beforeChange: handleBeforeChange,
  };

  return (
    <Root>
      <Slider {...settings} ref={sliderRef}>
        {data.map((item, index) => (
          <Slide
            key={index}
            image={item.image}
            active={activeSlide === index}
            index={index}
            style={
              activeSlide === index
                ? { pointerEvents: 'all' }
                : { pointerEvents: 'none' }
            }
          >
            <p>{item.title}</p>
            <h2>{item.description}</h2>
            <Button to={item.to} className="button">
              {item.buttonText}
            </Button>
            <Background
              index={index}
              image={item.image}
              key={
                activeSlide === index ||
                activeSlide === index + 1 ||
                index === prevSlide
              }
              active={activeSlide === index}
              previous={prevSlide === index}
              panLeft={item.panLeft}
              panTop={item.panTop}
              zoomIn={item.zoomIn}
            >
              <div />
            </Background>
          </Slide>
        ))}
      </Slider>
      <Navigation>
        {data.map((item, index) => (
          <Number
            onClick={() => sliderRef.current.slickGoTo(index)}
            key={index}
            active={activeSlide === index}
          >
            <h3>0{index + 1}</h3>
            <p>{item.title}</p>
            <div />
          </Number>
        ))}
      </Navigation>
      <Link to="whoweare" smooth>
        <Down
          src={require('src/assets/images/icons/down-arrow.svg')}
          alt="Scroll Down"
        />
      </Link>
      <Black />
    </Root>
  );
};

export default Hero;

const Root = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  color: ${({ theme }) => theme.color.white};
  p {
    font-size: ${vw(12)};
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
    }
  }
  h2 {
    margin: ${vw(12)} 0 0;
    font-size: ${vw(28)};
    line-height: 1.21;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(54)};
      margin: 0 0 ${vwTablet(32)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(54)};
      margin: 0 0 ${vwDesktop(32)};
    }
  }
  h2 span {
    font-weight: 500;
  }
  .slick-slide {
    pointer-events: none;
  }
  .slick-current {
    pointer-events: all;
  }
`;

const zoomOut = keyframes`
   0% {
    transform: scale(1.05) translate3d(0, -20px, 0);
   }
   100% {
    transform: scale(1) translate3d(0, 0, 0);
   }
`;

const zoomOutAnimation = css`
  animation: ${zoomOut} 8s ease-out forwards;
`;

const panLeft = keyframes`
  0% {
    transform: scale(1.05) translate3d(30px, -20px, 0);
  }
  100% {
    transform: scale(1.05) translate3d(-20px, -20px, 0);
  }
`;

const panLeftAnimation = css`
  animation: ${panLeft} 8s ease-out forwards;
`;

const panTop = keyframes`
  /* 0% {

      background-position: 75% 30%;
  }
  100% {

      background-position: 80% 25%;
  } */
  0% {
    transform: scale(1.03) translate3d(-20px, 0px, 0);
  }
  100% {
    transform: scale(1.03) translate3d(30px, -20px, 0);
  }
`;

const panTopAnimation = css`
  animation: ${panTop} 8s linear forwards;
`;

const zoomIn = keyframes`
   0% {
    transform: scale(1) translate3d(0, 0, 0);
   }
   100% {
    transform: scale(1.05) translate3d(0, -20px, 0);
   }
`;
const zoomInAnimation = css`
  animation: ${zoomIn} 8s ease-out forwards;
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  padding: ${vw(194)} ${vw(20)} 0;
  position: relative;
  overflow: hidden;
  .button {
    display: none;
  }
  h2 {
    max-width: ${props => {
      if (props.index === 0) {
        return vw(200);
      } else if (props.index === 2) {
        return vw(208);
      } else {
        return vw(236);
      }
    }};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(314)} ${vwTablet(60)} 0;
    .button {
      display: block;
    }
    h2 {
      max-width: ${vwTablet(516)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(294)} ${vwDesktop(120)} 0;
    h2 {
      max-width: ${vwDesktop(576)};
    }
  }
  p {
    @media (min-width: ${media.tablet}) {
      display: none;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (min-width: ${media.desktop}) {
    transform: ${props => {
      if (props.panLeft) {
        return `scale(1.05) translate3d(30px, -20px, 0)`;
      } else if (props.zoomIn) {
        return `scale(1) translate3d(0, 0, 0)`;
      } else if (props.panTop) {
        return `scale(1.0) translate3d(-20px, 0px, 0)`;
      } else {
        return `scale(1.05) translate3d(0, -20px, 0);`;
      }
    }};
    transform-origin: center;
    ${props => {
      if (props.panLeft) {
        return panLeftAnimation;
      } else if (props.zoomIn) {
        return zoomInAnimation;
      } else if (props.panTop) {
        return panTopAnimation;
      } else {
        return zoomOutAnimation;
      }
    }}
  }
  > div {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(${props => props.image});
    background-blend-mode: multiply;
    background-size: cover;
    background-position: ${props => (props.index === 0 ? '40%' : '65%')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media (min-width: ${media.tablet}) {
      background-position: 65%;
    }
    @media (min-width: ${media.desktop}) {
      background-attachment: fixed;
      background-position: ${props =>
        props.panTop ? 'center right' : 'center'};
      background-size: ${props => (props.panTop ? 'cover' : 'cover')};
      ${props => props.panTop && panTopAnimation};
    }
  }
`;

const Black = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-color: black;
`;

const Navigation = styled.div`
  position: absolute;
  bottom: ${vw(25)};
  left: ${vw(20)};
  display: flex;
  @media (min-width: ${media.tablet}) {
    bottom: ${vwTablet(140)};
    left: ${vwTablet(586)};
    flex-direction: column;
  }
  @media (min-width: ${media.desktop}) {
    top: ${vwDesktop(260)};
    bottom: auto;
    left: ${vwDesktop(1258)};
  }
  p {
    display: none;
    @media (min-width: ${media.tablet}) {
      display: block;
      margin: ${vwTablet(2)} 0 ${vwTablet(4)};
    }
    @media (min-width: ${media.desktop}) {
      display: block;
      margin: ${vwDesktop(2)} 0 ${vwDesktop(4)};
    }
  }
`;

const grow = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const growAnimation = css`
  animation: ${grow} 3s linear forwards;
`;

const Number = styled.div`
  margin-bottom: 0;
  margin-right: ${vw(12)};
  width: ${vw(32)};
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: 0.3s ease;
  cursor: pointer;
  > div {
    width: 100%;
    height: 1px;
    background-color: white;
    position: relative;
    &::after {
      content: '';
      width: 0%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.color.blue};
      opacity: ${props => (props.active ? 1 : 0)};
      ${props => props.active && growAnimation}
    }
  }
  @media (min-width: ${media.tablet}) {
    margin-right: 0;
    margin-bottom: ${vwTablet(24)};
    width: ${vwTablet(122)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(24)};
    width: ${vwDesktop(122)};
  }
`;

const Down = styled.img`
  position: absolute;
  bottom: ${vw(25)};
  right: ${vw(20)};
  width: ${vw(22.7)};
  height: ${vw(12.2)};
  cursor: pointer;
  @media (min-width: ${media.tablet}) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${vwTablet(16)};
    width: ${vwTablet(34)};
    height: ${vwTablet(18)};
  }
  @media (min-width: ${media.desktop}) {
    bottom: ${vwDesktop(32)};
    width: ${vwDesktop(34)};
    height: ${vwDesktop(18)};
  }
`;
