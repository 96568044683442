import React from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { Waypoint } from 'react-waypoint';

import Container from 'components/Container';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Stats = () => {
  const $countup1 = React.createRef();
  const $countup2 = React.createRef();
  const $countup3 = React.createRef();

  const counters = [$countup1, $countup2, $countup3];

  const startCounters = () => {
    return counters.map(item => item.current.start());
  };

  const resetCounters = () => {
    return counters.map(item => item.current.reset());
  };

  return (
    <Root>
      <Container>
        <Waypoint
          onEnter={() => startCounters()}
          onLeave={() => resetCounters()}
          scrollableAncestor={window}
          // topOffset="30%"
        >
          <div>
            <p>
              Today, we are one of Greater Vancouver’s leading new home builders
              with an achievement of building over 450 units per year.{' '}
            </p>
            <Wrapper>
              <div>
                <h1>
                  <CountUp end={1200} duration={3} start={0} ref={$countup1}>
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  +
                </h1>
                <div className="line" />
                <h4>
                  Homes
                  <br />
                  Completed
                </h4>
              </div>
              <div>
                <h1>
                  <CountUp end={1600} duration={3} start={0} ref={$countup2}>
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  +
                </h1>
                <div className="line" />
                <h4>
                  Homes
                  <br />
                  Under Construction
                </h4>
              </div>
              <div>
                <h1>
                  <CountUp end={1800} duration={3} start={0} ref={$countup3}>
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                  +
                </h1>
                <div className="line" />
                <h4>
                  Homes
                  <br />
                  Coming Soon
                </h4>
              </div>
            </Wrapper>
          </div>
        </Waypoint>
      </Container>
    </Root>
  );
};

export default Stats;

const Root = styled.div`
  padding: ${vw(80)} 0;
  text-align: center;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(140)} 0;
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(140)} 0;
  }
  p {
    font-size: ${vw(18)};
    color: ${({ theme }) => theme.color.black};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(20)};
      max-width: ${vwTablet(586)};
      margin: 0 auto;
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(20)};
      max-width: ${vwDesktop(588)};
      margin: 0 auto;
    }
  }
  h1 {
    font-weight: 500;
    display: flex;
    justify-content: center;
  }
  .line {
    display: block;
    width: ${vw(24)};
    height: 1px;
    background-color: ${({ theme }) => theme.color.blue};
    margin: ${vw(10)} auto;
    @media (min-width: ${media.tablet}) {
      width: ${vwTablet(24)};
      margin: ${vwTablet(20)} auto;
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(24)};
      margin: ${vwDesktop(20)} auto;
    }
  }
  h4 {
    font-size: ${vw(12)};
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey3};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
    }
  }
`;

const Wrapper = styled.div`
  > div {
    margin-top: ${vw(32)};
  }
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
    > div {
      margin-top: ${vwTablet(62)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: 0 ${vwDesktop(204)};
    > div {
      margin-top: ${vwDesktop(60)};
    }
  }
`;
