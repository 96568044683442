import React from 'react';

import PageLayout from 'components/PageLayout/PageLayout';
import Overview from './sections/Overview';
import ToifaAwards from './sections/ToifaAwards';

const sections = [
  {
    title: 'Overview',
    section: <Overview />,
  },
  {
    title: 'Toifa Awards',
    section: <ToifaAwards />,
  },
];

const Entertainment = () => {
  return (
    <PageLayout
      title="Entertainment"
      description="Along with a passion for creating innovative communities, Daljit Thind’s creativity is equally active in the film industry."
      hero={require('src/assets/images/entertainment/entertainment-Thind.jpg')}
      sections={sections}
    />
  );
};

export default Entertainment;
