import React from 'react';

export default [
  {
    quote: (
      <>
        The “King of Kingsway” has been busy over the past few years, building
        up projects throughout South Vancouver with a $70-million tower opening
        this month on the old Wally’s Burgers site, two towers near Metrotown,
        and one near the PNE completing next spring...
        <br />
        <br />
        But his real splash came as a very visible sponsor of the 2013 Times of
        India Film Awards; on the heels of that exposure, he’s begun film
        projects in India and Canada, which only strengthens his leadership role
        in the Indo-Canadian community.
      </>
    ),
    title: 'POWER 50',
    image: require('src/assets/images/about/publications/publication-the-power50-vancouver-magazine-Thind.jpg'),
    publication: 'Vancouver Magazine',
  },
  {
    quote: (
      <>
        Jewels of Punjab […] focuses on bringing forth the personal journeys of
        eminent Punjabis across the world, who have left their native lands and
        yet, through their vigour and determination are making India and Indians
        proud world over.
        <br />
        <br />
        Described as soft-spoken, forthright, tenacious, and an altruistic
        entrepreneur who will conduct all business transactions with the utmost
        integrity, Mr. Daljit Thind, the CEO and founder of Thind Properties, is
        widely respected in the development circles.
      </>
    ),
    title: 'leading global punjabi personalities',
    image: require('src/assets/images/about/publications/publication_jewels_of_punjab_Thind.jpg'),
    publication: 'jewels of punjab',
  },
  {
    quote: (
      <>
        While his company has grown by leaps and bounds, Thind remains as
        hands-on as the day he oversaw his first project, shepherding each
        aspect of his signature developments from the foundations to the
        rooftops...
        <br />
        <br />
        Thind also places a premium on being a good community citizen both here
        in his adopted homeland as well as in his native India.
      </>
    ),
    title: 'Vancouver Profiles of Excellence',
    image: require('src/assets/images/about/publications/publication_vancouver_magazine_Thind.jpg'),
    publication: 'Vancouver Courier',
  },
  {
    quote: (
      <>
        Thind Properties’ head office is a hidden hive of activity. The
        2,000-square-foot unit on the ground floor of a low-rise looks
        unremarkable from the outside with no signs to identify the company to
        passersby. Yet the enterprise is one of B.C.’s fastest-growing
        development empires...
        <br />
        <br />
        Large framed magazine features on Thind Properties’ evolution sit on the
        floor against a wall, waiting to be hung and providing the only clue
        that the man sitting behind the large desk has developed about $400
        million worth of real estate, has another $300 million or so on the
        books and has global real estate ambitions.
      </>
    ),
    image: require('src/assets/images/about/publications/publication-business-vancouver-Thind.jpg'),
    publication: 'Business In Vancouver',
  },
  {
    quote: (
      <>
        Daljit Thind has capsized the phrase “build it and they will come.” By
        transforming his humble beginnings into a path of developing custom
        homes, townhomes, condominiums, and current a project in excess of $100
        million - Thind has become an industry pillar. His rapid success in such
        a short time characterizes the best attributes of immigrant
        entrepreneurialism. Thind is a man of integrity, someone who is
        relentless about working hard, doing things the right way, and putting
        his heart into his business.
      </>
    ),
    title: 'Business Leaders Profile',
    image: require('src/assets/images/about/publications/publication_darpan_magazine_Thind.jpg'),
    publication: 'Darpan Magazine',
  },
  {
    quote: (
      <>
        Thind’s range of real estate development has been a major catalyst in
        housing within the Lower Mainland - unlocking the ability to build
        faster and more efficiently. In turn, his company has helped to provide
        hundreds of homes for families. Growth has been wild. From afar, one
        does not know how to comprehend Thind’s success, but once you meet him,
        it’s hard not to root for him. Naturally inquisitive, genuinely nice and
        humble, Thind is not one to broadcast his achievements. That makes him
        an even more likable guy.
      </>
    ),
    image: require('src/assets/images/about/publications/publication_drishti_magazine_Thind.jpg'),
    publication: 'Drishti Magazine',
  },
];
