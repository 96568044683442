import React, { Component } from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Whoweare from './sections/Whoweare';
import Stats from './sections/Stats';
import ComingSoon from './sections/ComingSoon';
import Properties from './sections/Properties';
import Leadership from './sections/Leadership';
// import Video from './sections/Video';

class Home extends Component {
  render() {
    return (
      <Root>
        <Hero />
        <Whoweare />
        <Stats />
        <ComingSoon />
        <Properties />
        {/* <Video /> */}
        <Leadership />
      </Root>
    );
  }
}

export default Home;

const Root = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;
