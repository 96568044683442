import React, { useState } from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Video = () => {
  const $video = React.createRef();

  const [isPlaying, setIsPlaying] = useState(false);

  const startVideo = () => {
    $video.current.play();
    setIsPlaying(true);
  };

  const stopVideo = () => {
    $video.current.pause();
    setIsPlaying(false);
  };
  return (
    <Root isPlaying={isPlaying} onClick={isPlaying ? stopVideo : startVideo}>
      <video
        src="https://player.vimeo.com/external/353862318.hd.mp4?s=9b14c0b57135af8809c0d996d29a2dd12dda676e&profile_id=175"
        ref={$video}
      />
      <div>
        <img
          src={require('src/assets/images/icons/play-icon.svg')}
          alt="Play"
        />
        <h3>Our Story</h3>
      </div>
    </Root>
  );
};

export default Video;

const Root = styled.div`
  height: ${vw(200)};
  color: white;
  position: relative;
  margin-bottom: ${vw(80)};
  cursor: pointer;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease;
    opacity: ${props => (props.isPlaying ? 0 : 1)};
  }
  img {
    width: ${vw(60)};
    height: ${vw(60)};
    margin-bottom: ${vw(12)};
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(300)};
    margin: 0 ${vwTablet(60)} ${vwTablet(140)};
    img {
      width: ${vwTablet(96)};
      height: ${vwTablet(96)};
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(500)};
    margin: 0 ${vwDesktop(120)} ${vwDesktop(156)};
    img {
      width: ${vwDesktop(96)};
      height: ${vwDesktop(96)};
      margin-bottom: ${vwDesktop(24)};
    }
  }
`;
