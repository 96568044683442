import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.blue};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: ${vw(40)};
    font-weight: 300;
    line-height: 1.3;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(54)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(54)};
    }
  }


  h2 {
    font-size: ${vw(32)};
    font-weight: 300;
    line-height: 1.3;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(44)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(44)};
    }
  }


  h3 {
    font-size: ${vw(22)};
    font-weight: 300;
    line-height: 1.36;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(28)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(28)};
    }
  }

  h4 {
    font-size: ${vw(12)};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
    }
  }

  p {
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(20)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(20)};
    }
  }

  p.body-large {
    font-size: ${vw(18)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(20)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(20)};
    }
  }

  p.body-1 {
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(16)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(16)};
    }
  }

  p.body-2 {
    font-size: ${vw(13)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(14)};
    }
  }

  label {
    display: block;
    font-size: ${vw(12)};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey3};
    margin-bottom: ${vw(4)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(4)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(4)};
    }
  }
`;
