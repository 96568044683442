import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

// ** DOES NOT WORK WITH BACKGROUND IMAGES

const Loader = props => {
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    // can use this as a loader for a gallery or specific element, just replace document with the parent element
    let images = document.getElementsByTagName('img');
    let iterationCount = 0;
    const interval = setInterval(() => {
      iterationCount += 1;
      let loaded = true;
      for (const img of images) {
        if (!img.complete) {
          loaded = false;
        }
      }
      if (loaded) {
        setLoaded(true);
        clearInterval(interval);
      }
      // checks if the interval has surpassed 5s (** Change the 100 number to change how long it waits)
      if (iterationCount > 100) {
        setLoaded(true);
        clearInterval(interval);
      }
    }, 50);
  }, []);

  return (
    <Root
      loaded={isLoaded}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        flexDirection: 'column',
      }}
    >
      <img
        src={require('src/assets/images/logos/thind-loader@2x.png')}
        alt="Thind"
        style={{ width: '280px' }}
      />
    </Root>
  );
};

export default Loader;

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: ${props => (props.loaded ? 0 : 1)};
  pointer-events: ${props => (props.loaded ? 'none' : 'all')};
  transition: 0.3s ease;
  img {
    width: ${vw(180)};
    margin-bottom: ${vw(7)};
  }
  @media (min-width: ${media.tablet}) {
    img {
      width: ${vwTablet(280)};
      margin-bottom: ${vwTablet(11)};
    }
  }
  @media (min-width: ${media.desktop}) {
    img {
      width: ${vwDesktop(280)};
      margin-bottom: ${vwDesktop(12)};
    }
  }
`;
