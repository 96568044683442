import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Quote = props => {
  const { text, margin } = props;
  return (
    <Root margin={margin}>
      <p>{text}</p>
    </Root>
  );
};

Quote.propTypes = {
  text: PropTypes.string,
};

export default Quote;

const Root = styled.div`
  margin: ${vw(60)} 0 ${props => (props.margin ? vw(60) : 0)};
  position: relative;
  display: flex;
  flex-direction: column;
  &::before {
    content: '';
    width: ${vw(60)};
    height: 1px;
    background-color: ${({ theme }) => theme.color.blue};
    display: block;
    position: relative;
    top: 0;
    left: ${vw(-20)};
    margin-bottom: ${vw(16)};
  }
  &::after {
    content: '';
    width: ${vw(120)};
    height: 1px;
    background-color: ${({ theme }) => theme.color.blue};
    display: block;
    position: relative;
    bottom: 0;
    right: ${vw(-20)};
    margin-top: ${vw(16)};
    align-self: flex-end;
  }
  p {
    font-size: ${vw(16)};
    font-style: italic;
    line-height: 1.5;
    letter-spacing: ${vw(0.5)};
    color: ${({ theme }) => theme.color.blue};
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(100)} 0 ${props => (props.margin ? vwTablet(100) : 0)};
    &::before {
      position: absolute;
      width: ${vwTablet(60)};
      left: 0;
      top: ${vwTablet(13)};
      margin-bottom: 0;
    }
    &::after {
      position: absolute;
      width: ${vwTablet(120)};
      right: 0;
      bottom: ${vwTablet(13)};
      margin-top: 0;
    }
    p {
      font-size: ${vwTablet(20)};
      letter-spacing: ${vwTablet(0.5)};
      width: ${vwTablet(420)};
      margin: 0 0 0 ${vwTablet(84)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(100)} 0 ${props => (props.margin ? vwDesktop(100) : 0)};
    &::before {
      position: absolute;
      width: ${vwDesktop(78)};
      left: 0;
      top: ${vwDesktop(13)};
      margin-bottom: 0;
    }
    &::after {
      position: absolute;
      width: ${vwDesktop(180)};
      right: 0;
      bottom: ${vwDesktop(13)};
      margin-top: 0;
    }
    p {
      font-size: ${vwDesktop(20)};
      letter-spacing: ${vwDesktop(0.5)};
      width: ${vwDesktop(588)};
      margin: 0 0 0 ${vwDesktop(102)};
    }
  }
`;
