export default {
  highline: {
    title: 'Highline',
    slug: 'highline',
    tagline: 'Experience upscale living at the tallest tower on Beresford.',
    status: 'Coming Soon',
    description:
      'Highline offers beautiful 1, 2, and 3-bedroom homes in the radiant centre of Metrotown. Featuring a 11th floor Club Highline for residents, you can live your best life with your own spa, fitness, and wellness centre. Surrounded by shops, food, parks, and transportation to keep you connected and moving. Make your move with Highline.',
    website: 'http://www.thind.ca/highline/',
    map: true,
    lat: 49.224678,
    lng: -123.002636,
    address: '6511 Sussex Avenue, Burnaby',
    updates: 'Registration now open. Presentation Centre coming soon.',
    residential: 327,
    commercial: '10 floors of office with ground floor commercial space',
    completion: 2023,
    features: [
      'Located in Metrotown’s most desirable location',
      'Steps away from Metrotown Skytrain Station, shops, restaurants, and amenities',
      'More information coming soon!',
    ],
    image: require('src/assets/images/properties/highline-daytime.jpg'),
    logo: require('src/assets/images/properties/logos/Highline-logo.png'),
  },
  'lumina-alpha': {
    title: 'Lumina Alpha',
    slug: 'lumina-alpha',
    tagline: 'Master-planned residential community in the heart of Brentwood.',
    status: 'Now Selling',
    lumina: true,
    description:
      'Find life in balance in the heart of Brentwood at Lumina. This master-planned residential community with one, two, and three-bedroom condos for sale is a place where city and nature come together in perfect harmony. Lumina’s four towers each embody a unique design reflective of elements in the natural world. Surrounding the towers are a collection of townhomes, each with their own private entryway. Outside, bustling shops and peaceful parks are just steps from your front door, in the most exciting transit-oriented community Burnaby real estate has to offer.',
    website: 'https://luminabrentwood.com/',
    map: true,
    lat: 49.263626,
    lng: -122.999887,
    address: '2288 Alpha Avenue, Burnaby',
    updates: 'Now selling, call to enquire',
    residential: 160,
    completion: 2020,
    features: [
      'Residential-only community',
      'Concierge service',
      'Integrated Bosch appliance package',
      'Air conditioning',
      'Neighbouring a new 13-acre park',
    ],
    image: require('src/assets/images/properties/lumina-alpha.jpg'),
    logo: require('src/assets/images/properties/logos/lumina-logo.png'),
  },
  'lumina-starling': {
    title: 'Lumina Starling',
    slug: 'lumina-starling',
    lumina: true,
    tagline: 'Master-planned residential community in the heart of Brentwood.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Find life in balance in the heart of Brentwood at Lumina. This master-planned residential community with one, two, and three-bedroom condos for sale is a place where city and nature come together in perfect harmony. Lumina’s four towers each embody a unique design reflective of elements in the natural world. Surrounding the towers are a collection of townhomes, each with their own private entryway. Outside, bustling shops and peaceful parks are just steps from your front door, in the most exciting transit-oriented community Burnaby real estate has to offer.',
    website: 'https://luminabrentwood.com/',
    map: true,
    lat: 49.263094,
    lng: -122.997678,
    address: '2381 Beta Ave, Burnaby',
    updates: 'Sold out. Under construction.',
    residential: 221,
    completion: 2020,
    features: [
      'Residential-only community',
      'Concierge service',
      'Integrated Bosch appliance package',
      'Air conditioning',
      'Neighbouring a new 13-acre park',
    ],
    image: require('src/assets/images/properties/lumina-starling.jpg'),
    logo: require('src/assets/images/properties/logos/lumina-logo.png'),
  },
  'lumina-waterfall': {
    title: 'Lumina Waterfall',
    slug: 'lumina-waterfall',
    lumina: true,
    tagline: 'Master-planned residential community in the heart of Brentwood.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Find life in balance in the heart of Brentwood at Lumina. This master-planned residential community with one, two, and three-bedroom condos for sale is a place where city and nature come together in perfect harmony. Lumina’s four towers each embody a unique design reflective of elements in the natural world. Surrounding the towers are a collection of townhomes, each with their own private entryway. Outside, bustling shops and peaceful parks are just steps from your front door, in the most exciting transit-oriented community Burnaby real estate has to offer.',
    website: 'https://luminabrentwood.com/',
    map: true,
    lat: 49.264119,
    lng: -122.998223,
    address: '2311 Beta Ave, Burnaby',
    updates: 'Sold out. Under construction.',
    residential: 289,
    completion: 2020,
    features: [
      'Residential-only community',
      'Concierge service',
      'Integrated Bosch appliance package',
      'Air conditioning',
      'Neighbouring a new 13-acre park',
    ],
    image: require('src/assets/images/properties/lumina-waterfall.jpg'),
    logo: require('src/assets/images/properties/logos/lumina-logo.png'),
  },
  pixel: {
    title: 'Pixel',
    slug: 'pixel',
    tagline: 'Perfectly positioned between Metrotown and Edmonds.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Pixel is your opportunity to live, play and invest in a growing neighbourhood, that is quickly becoming the place to be in Burnaby. The modern low-rise boasts affordable and stylish homes. With low maintenance fees, smart floor plans, and in-suite guest/flex rooms, Pixel is truly designed for everyday living.',
    address: '6205 Kingsway, Burnaby',
    residential: 101,
    commercial: 9,
    completion: '2018',
    features: [
      'Centrally located in the Deer Lake Park neighbourhood',
      'Great restaurants, grocery stores and shopping nearby',
      'Well-designed floorplans and stylish interiors',
      'Outdoor living space',
    ],
    image: require('src/assets/images/properties/pixel-community.jpg'),
    logo: require('src/assets/images/properties/logos/pixel-logo.png'),
  },
  '1st-and-renfrew': {
    title: '1st & Renfrew',
    slug: '1st-and-renfrew',
    tagline: 'Location, quality, and affordability come together.',
    soldOut: true,
    status: 'Sold Out',
    description:
      '1st & Renfrew is your opportunity to own a piece of Vancouver real estate and live just minutes from everything. The modern four storey wood and concrete development is just minutes away from the hustle and bustle of Downtown. Schools, parks and shopping are all within walking distance. You’ll also find and expect expertly designed features inside this project.',
    address: 'East 1st Avenue, Vancouver',
    residential: 74,
    commercial: 9,
    completion: '2019',
    features: [
      'Located on the edge of it all',
      'Secure parking with bike storage',
      'Amenity room and patio',
      'Protected by 2-5-10 year warranty',
      'Quartz countertops',
      'Contemporary design and finishings',
    ],
    image: require('src/assets/images/properties/1st-and-renfrew-community.jpg'),
    logo: require('src/assets/images/properties/logos/1strenfrew-logo.png'),
  },
  '2nd-and-renfrew': {
    title: '2nd & Renfrew',
    slug: '2nd-and-renfrew',
    tagline: 'Vancouver real estate at its most accessible.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Proving that quality can also be affordable, 2nd & Renfrew is Vancouver real estate at its most accessible. Our new development in Vancouver is the combined result of latest technology, great location, plus Thind’s construction expertise and timeless design standards. Enjoy a proximity to shopping, transportation, public parks and facilities which are just outside your doorstep.',
    address: '1838 Renfrew Street, Vancouver',
    residential: 48,
    commercial: 6,
    completion: '2017',
    features: [
      'Located on the edge of it all',
      'Secure parking with bike storage',
      'Amenity room and patio',
      'Protected by 2-5-10 year warranty',
      'Quartz countertops',
      'Contemporary design and finishings',
    ],
    image: require('src/assets/images/properties/2nd-and-renfrew-community.jpg'),
    logo: require('src/assets/images/properties/logos/second-and-renfrew-logo.png'),
  },
  'view-388': {
    title: 'View 388',
    slug: 'view-388',
    tagline: 'Choose from 53 homes in 1, 2, and 3 bedroom configurations.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'View 388 sets a new standard for this fast growing area in the heart of Vancouver East and is the perfect balance of affordability and luxury with a spectacular view! Choose from 53 homes in 1, 2 and 3 bedroom configurations including an amazing penthouse with its very own private viewing deck that will take your breath away!',
    address: '388 Kootenay Street, Vancouver',
    residential: 53,
    commercial: 7,
    completion: '2015',
    features: [
      'Located in Vancouver East',
      'Spectacular views',
      'Spacious layouts',
      'Luxurious finishings',
      'Affordably priced',
    ],
    image: require('src/assets/images/properties/view-388-community.jpg'),
    logo: require('src/assets/images/properties/logos/view-388-logo.png'),
  },
  'skyway-tower': {
    title: 'Skyway Tower',
    slug: 'skyway-tower',
    tagline: `In the heart of Vancouver's new vibrant Norquay Community.`,
    soldOut: true,
    status: 'Sold Out',
    description:
      'Skyway Tower is Thind’s premier “Signature Gold Development”, which includes some of the finest environmentally progressive features within a concrete 12 storey hi-rise and 4 storey mid-rise. Skyway Tower is built on Thind’s primary goal of providing quality and affordability in one elegant package.',
    address: '2699 Kingsway, Vancouver',
    residential: 130,
    commercial: 10,
    completion: '2014',
    features: [
      'Located in the heart of Vancouver',
      'Quality concrete construction',
      'Thoughtfully designed interiors',
      'High-end laminate flooring',
      'Durable ceramic tiles',
      'Modern appliances and fittings',
    ],
    image: require('src/assets/images/properties/thind-skyway-tower.jpg'),
    logo: require('src/assets/images/properties/logos/skyway-logo.png'),
  },
  'metro-two': {
    title: 'Metro Two',
    slug: 'metro-two',
    tagline:
      'Phase 2 of the successful development, with Metrotown Centre at your doorstep.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Metro Two is part of a successful 2 phase development project built on prime Burnaby real estate that is steps from Metrotown Centre. Metro Two features a beautiful inner courtyard as well as select units with amazing views to the north and south. Select from 1, 2 and 3 bedroom homes with spacious 9’ ceilings and designer finishes throughout.',
    address: '5352 Grimmer Street, Burnaby',
    residential: 73,
    commercial: 7,
    completion: '2014',
    features: [
      'Beautiful inner courtyard',
      'Views to the north and south',
      'Spacious 9’ ceilings in select units',
      'Designer finishes throughout',
    ],
    image: require('src/assets/images/properties/metro-two-community.jpg'),
    logo: require('src/assets/images/properties/logos/metro-two-logo.png'),
  },
  'metro-one': {
    title: 'Metro One',
    slug: 'metro-one',
    tagline: 'Form and function in perfect harmony.',
    soldOut: true,
    status: 'Sold Out',
    description2:
      'Metro One is part of a successful 2 phase development project built on prime Burnaby real estate that is steps from Metrotown Centre. The community features a beautiful inner courtyard as well as select units with amazing views to the north and south. Select from 1, 2 and 3 bedroom homes with spacious 9’ ceilings and designer finishes throughout.',
    address: '5248 Grimmer Street, Burnaby',
    residential: 73,
    commercial: 7,
    completion: '2013',
    features: [
      'Beautiful inner courtyard',
      'Views to the north and south',
      'Spacious 9’ ceilings in select units',
      'Designer finishes throughout',
    ],
    image: require('src/assets/images/properties/metro-one-community.jpg'),
    logo: require('src/assets/images/properties/logos/metro-one-logo.png'),
  },
  'casa-doro': {
    title: 'Casa d’Oro',
    slug: 'casa-doro',
    tagline: '46 quality built townhomes in bustling Metrotown.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Casa d’Oro is THIND’s latest premium townhome community development located in Central Burnaby. Choose from 46 quality built homes available in 2 and 3 bedroom configurations. Function and form truly come together with each home enjoying 3 levels combined with its own private garage.',
    address: '7533 Gilley Avenue, Burnaby',
    residential: 46,
    completion: '2013',
    features: [
      'Expertly designed floor plans',
      'Over 3 levels of living space',
      'Private garages',
      'Located steps from Metrotown',
    ],
    image: require('src/assets/images/properties/casa-doro-community.jpg'),
    logo: require('src/assets/images/properties/logos/casa-doro-logo.png'),
  },
  'the-scena': {
    title: 'The Scéna',
    slug: 'the-scena',
    tagline: 'A Thind Signature Development.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'The Scéna became our first project to be called a “THIND Signature Development” which resulted in great marketing success. The Scéna included 1, 2 and 3 bedroom homes as well as 2 level townhomes with some units with over 900 square feet. The unique architecture included 2 sides to the building separated by a central walkway.',
    address: '2239 Kingsway, Vancouver',
    residential: 95,
    commercial: 12,
    completion: '2011',
    features: [
      'Inspired exterior brickwork',
      'Innovative architecture',
      'Innovative architecture',
      'Conveniently located',
    ],
    image: require('src/assets/images/properties/the-scena-community.jpg'),
    logo: require('src/assets/images/properties/logos/the-scena-logo.png'),
  },
  'the-view': {
    title: 'The View',
    slug: 'the-view',
    tagline: 'Quality and affordability meet the Vancouver real estate market.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'The View is located in the heart of Vancouver with 57 finely crafted city homes in one and two bedroom layouts. Select units include a den or a spectacular rooftop deck to suit your every need. In addition, The View provided custom designer finish packages to suit the buyer.',
    address: '2150 Hastings Street, Vancouver',
    residential: 57,
    commercial: 10,
    completion: '2009',
    features: [
      'Custom designer finish packages',
      'Spectacular views',
      'Conveniently located near transit',
      'Easy access to shopping & restaurants',
      'Schools & community centre nearby',
    ],
    image: require('src/assets/images/properties/thind-theview.jpg'),
    logo: require('src/assets/images/properties/logos/the-view-logo.png'),
  },
  mondella: {
    title: 'Mondella',
    slug: 'mondella',
    tagline: '43 finely crafted townhomes in Vancouver that raise the bar.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Mondella sets a new standard for this Vancouver neighbourhood with 43 finely crafted city homes that are only 10 minutes away from downtown. Even closer to home, you’ll be walking distance to trendy shops, cafes, and restaurants in the Mount Pleasant area. High ceilings, large windows, spacious decks, and contemporary finishings bring value to not just a home but a quality investment. Mondella is all about comfort, style, convenience and community.',
    address: '688 East 17th Avenue, Vancouver',
    residential: 43,
    commercial: 7,
    completion: '2008',
    features: [
      'In-suite laundry',
      'Elevator',
      'Garden area',
      'Underground parking',
      'Electric fireplaces',
    ],
    image: require('src/assets/images/properties/modella-community.jpg'),
    logo: require('src/assets/images/properties/logos/mondella-logo.png'),
  },
  'metro-pointe-gardens': {
    title: 'Metro Pointe Gardens',
    slug: 'metro-pointe-gardens',
    tagline: 'Burnaby townhomes designed with your family in mind.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Welcome to Metro Pointe Gardens — townhomes designed with your family in mind. The spacious 2 and 3 bedrooms with convenient in-home storage offer your family the comfort and space you deserve. By combining quality finishings with its central location close to shopping and schools, Metro Pointe Gardens sets the standards.',
    address: '5155 Watling Street, Burnaby',
    residential: 38,
    completion: '2007',
    features: [
      'Beautiful interior courtyard walkways',
      'Landscaped grounds',
      'Secured underground parking',
      'Granite countertops',
      'Electric fireplaces',
      'Spacious 9’ ceilings',
    ],
    image: require('src/assets/images/properties/thind-metro-pointe-gardens.jpg'),
    logo: require('src/assets/images/properties/logos/metro-pointe-logo.png'),
  },
  southgate: {
    title: 'Southgate',
    slug: 'southgate',
    tagline: 'A premier community development with 55 quality townhomes.',
    soldOut: true,
    status: 'Sold Out',
    description:
      'Southgate is Thind’s first milestone achievement that has become the pivotal moment in creating one of Vancouver’s leading real estate development companies. Southgate features 55 quality townhomes in prime Burnaby real estate that continues to shine with great pride as our premier community development.',
    address: '7333 East 16th Avenue, Burnaby',
    residential: 55,
    completion: '2006',
    features: [
      'Comfortable urban interiors',
      'Spacious 9’ ceilings',
      'Expertly designed kitchens',
      'Solid granite countertops',
      'Secure underground parking',
    ],
    image: require('src/assets/images/properties/southgate-community.jpg'),
    logo: require('src/assets/images/properties/logos/Southgate-logo.png'),
  },
};
