import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Navigation = props => {
  const { sections, handleWidth, activeSection } = props;
  return (
    <Root>
      {sections.map((item, index) => (
        <Section key={index} active={activeSection === index}>
          <h3>0{index + 1}</h3>
          <h4>{item.title}</h4>
          <div>
            <span
              style={{
                width: handleWidth(index),
              }}
            />
          </div>
        </Section>
      ))}
    </Root>
  );
};

Navigation.propTypes = {
  sections: PropTypes.array,
  handleWidth: PropTypes.func,
  activeSection: PropTypes.number,
};

export default Navigation;

const Root = styled.div`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: block;
    position: sticky;
    top: ${vwDesktop(80)};
    height: fit-content;
    margin-right: ${vwDesktop(126)};
    padding-bottom: ${vwDesktop(140)};
    h3 {
      margin-bottom: ${vwDesktop(2)} !important;
    }
  }
`;

const Section = styled.div.attrs(props => ({
  style: props.active ? { opacity: 1 } : { opacity: 0.5 },
}))`
  width: ${vwDesktop(180)};
  margin-bottom: ${vwDesktop(24)};
  transition: 0.3s ease;
  > div {
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.grey6};
    position: relative;
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      min-width: 0%;
      max-width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.color.blue};
    }
  }
  h4 {
    margin-bottom: ${vwDesktop(4)};
    color: ${({ theme }) => theme.color.black} !important;
  }
`;
