import React, { useState } from 'react';
import styled from 'styled-components';

import Wrapper from 'components/PageLayout/elements/Wrapper';
import ImageSection from 'components/PageLayout/elements/ImageSection';
import SubButton from 'components/SubButton';
import Popup from './Popup';

import data from 'src/data/team';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Team = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [activeTeamMember, setActiveTeamMember] = useState(0);

  const $slider = React.createRef();

  const handleTeamMember = index => {
    setIsPopupOpen(true);
    document.body.style.overflow = 'hidden';
    setActiveTeamMember(index);
    $slider.current.slickGoTo(index, true);
  };

  const closePopup = () => {
    document.body.style.overflow = 'visible';
    setIsPopupOpen(false);
  };

  const nextIndex = () => {
    if (activeTeamMember === data.length - 1) {
      return 0;
    } else {
      return activeTeamMember + 1;
    }
  };

  const prevIndex = () => {
    if (activeTeamMember === 0) {
      return data.length - 1;
    } else {
      return activeTeamMember - 1;
    }
  };

  return (
    <Root>
      <Wrapper>
        <h4>Team</h4>
        <h3>Dedicated &amp; Experienced</h3>
        <p className="body-1">
          Each and every member of our dedicated staff brings a world of
          knowledge along with a thorough understanding of the Vancouver real
          estate industry. Their experience, coupled with genuine passion
          ensures that each space is created with the home owner’s needs and
          wants in mind.
        </p>
      </Wrapper>
      <ImageSection
        image={require('src/assets/images/about/Thind-Group-Shot.jpg')}
        title="Unwavering Commitment"
        text="It all began in 1996 when Daljit developed his first property – a single-family home. Although it had its challenges, it was also full of vital learning moments that he took to heart. Most importantly, Daljit realized that real estate development was his true passion, and so, with renewed energy and focus, he took the next step and has never looked back."
        rightImage
      />
      <Wrapper>
        <h3>Meet Our Leadership Team</h3>
        <Container>
          {data.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt={item.name} />
              <p className="body-1">{item.name}</p>
              <h4>{item.subTitle}</h4>
              <SubButton handleClick={() => handleTeamMember(index)}>
                Read More
              </SubButton>
              <div className="overlay" onClick={() => handleTeamMember(index)}>
                <SubButton white>Read More</SubButton>
              </div>
            </div>
          ))}
        </Container>
      </Wrapper>
      <Popup
        isPopupOpen={isPopupOpen}
        closePopup={closePopup}
        data={data}
        sliderRef={$slider}
        setActiveTeamMember={setActiveTeamMember}
        prevIndex={prevIndex}
        nextIndex={nextIndex}
      />
    </Root>
  );
};

export default Team;

const Root = styled.div`
  @media (min-width: ${media.tablet}) {
    p {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    p {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${vw(32)};
  > div {
    width: ${vw(128)};
    margin-bottom: ${vw(34)};
  }
  img {
    width: 100%;
    height: ${vw(154)};
  }
  h4 {
    color: ${({ theme }) => theme.color.grey3};
    margin: ${vw(8)} 0;
  }
  .overlay {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(40)};
    justify-content: flex-start;
    > div {
      width: ${vwTablet(200)};
      margin-bottom: ${vwTablet(42)};
      margin-right: ${vwTablet(24)};
    }
    > div:nth-of-type(3n) {
      margin-right: 0;
    }
    img {
      height: ${vwTablet(240)};
    }
    h4 {
      margin: 0;
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(40)};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    > div {
      width: ${vwDesktop(205)};
      margin-bottom: ${vwDesktop(40)};
      margin-right: ${vwDesktop(24)};
      position: relative;
      cursor: pointer;
    }
    .overlay {
      content: '';
      width: 100%;
      height: ${vwDesktop(240)};
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:hover .overlay {
      opacity: 1;
    }
    > div:nth-of-type(3n) {
      margin-right: ${vwDesktop(24)};
    }
    > div:nth-of-type(4n) {
      margin-right: 0;
    }
    img {
      height: ${vwDesktop(240)};
      margin-bottom: ${vwDesktop(8)};
    }
    > div > button {
      display: none;
    }
  }
`;
