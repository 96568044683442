import React from 'react';
import styled from 'styled-components';

import Input from 'components/Form/elements/Input';
import Textarea from 'components/Form/elements/Textarea';
import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Form = () => {
  return (
    <Root>
      <h4>Send us a message</h4>
      <p className="body-1">
        If you have any questions, feel free to send us a message using the form
        below and one of our team members will get back to you as soon as
        possible.
      </p>

      <form
        id="form"
        // onSubmit={handleForm}
        action="https://i.bamcommunications.ca/mailer/thind-contact-mailer.php"
        method="post"
      >
        <div className="inputs">
          <input
            type="hidden"
            name="retURL"
            value={`${window.location.origin}/thankyou`}
          />
          <Input
            label="Full Name *"
            name="name"
            id="name"
            required
            pattern="[a-zA-Z ]+"
          />
          <Input
            label="Phone Number *"
            name="phone"
            id="phone"
            required
            type="tel"
            pattern="^[(]?[0-9]{3}[)]?[ ,-.]?[0-9]{3}[ ,-.]?[0-9]{4}$"
          />
          <Input
            label="Email Address *"
            name="email"
            id="email"
            required
            type="email"
          />
          <Textarea name="message" id="message" required label="Message *" />
          <h4 className="grey">* Required Fields</h4>
          <Button blue transparent submit>
            Submit
          </Button>
        </div>
      </form>
    </Root>
  );
};

export default Form;

const Root = styled.div`
  padding: ${vw(40)} ${vw(20)} ${vw(80)};
  position: relative;
  h4 {
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
  }
  h4.grey {
    color: ${({ theme }) => theme.color.grey3};
    margin-bottom: ${vw(32)};
  }
  p {
    margin-bottom: ${vw(32)};
  }
  form {
    position: relative;
  }
  .inputs {
    opacity: ${props => (props.isSent ? 0 : 1)};
    pointer-events: ${props => (props.isSent ? 'none' : 'all')};
    transition: 0.5s ease;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(60)} 0 ${vwTablet(140)};
    max-width: ${vwTablet(536)};
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
    h4.grey {
      margin-bottom: ${vwTablet(32)};
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 0 ${vwDesktop(140)};
    max-width: ${vwDesktop(486)};
    h4 {
      margin-bottom: ${vwDesktop(12)};
    }
    h4.grey {
      margin-bottom: ${vwDesktop(32)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
    }
  }
`;
