import React from 'react';
import styled from 'styled-components';

import Wrapper from 'components/PageLayout/elements/Wrapper';
import Input from 'components/Form/elements/Input';
import Textarea from 'components/Form/elements/Textarea';
import Select from 'components/Form/elements/Select';
import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const properties = [
  '1st & Renfrew',
  '2nd & Renfrew',
  `Casa D'Oro`,
  'Metro One',
  'Metro Pointe Gardens',
  'Metro Two',
  'Mondella',
  'Pixel',
  'Skyway Tower',
  'Southgate',
  'The Scena',
  'The View',
  'View 388',
];

const Contact = () => {
  return (
    <Wrapper>
      <Root id="contact">
        <h4>Homeowner Care</h4>
        <h3>Service Request</h3>
        <form
          id="form"
          action="https://i.bamcommunications.ca/mailer/thind-homeownercare-mailer.php"
          method="post"
        >
          <div className="inputs">
            <input
              type="hidden"
              name="retURL"
              value={`${window.location.origin}/thankyou`}
            />
            <Input
              name="name"
              id="name"
              label="Full Name *"
              required
              pattern="[a-zA-Z ]+"
            />
            <Input
              name="phone"
              id="phone"
              label="Phone Number *"
              required
              type="tel"
              pattern="^[(]?[0-9]{3}[)]?[ ,-.]?[0-9]{3}[ ,-.]?[0-9]{4}$"
            />
            <Input
              label="Email Address *"
              name="email"
              id="email"
              required
              type="email"
            />
            <Select
              name="property"
              label="Property *"
              id="property"
              options={properties}
              required
            />
            <Input label="Unit # *" name="home" id="home" required />
            <h4 className="grey">are you the primary home owner? *</h4>
            <Radio>
              <div>
                <input
                  type="radio"
                  name="owner"
                  id="ownerY"
                  value="Yes"
                  required
                />
                <span />
                <label htmlFor="ownerY">Yes</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="owner"
                  id="ownerN"
                  value="No"
                  required
                />
                <span />
                <label htmlFor="ownerN">No</label>
              </div>
            </Radio>
            <Textarea name="message" id="message" required label="Message *" />
            <h4 className="grey margin">* Required Fields</h4>
            <Button blue transparent submit>
              Submit
            </Button>
          </div>
        </form>
      </Root>
    </Wrapper>
  );
};

export default Contact;

const Root = styled.div`
  margin-bottom: ${vw(80)};
  .grey {
    color: ${({ theme }) => theme.color.grey3};
  }
  .margin {
    margin-bottom: ${vw(32)};
  }
  form {
    position: relative;
  }
  .inputs {
    opacity: ${props => (props.isSent ? 0 : 1)};
    max-height: ${props => (props.isSent ? '200px' : '5000px')};
    pointer-events: ${props => (props.isSent ? 'none' : 'all')};
    transition: 0.5s ease;
  }
  @media (min-width: ${media.tablet}) {
    max-width: ${vwTablet(536)};
    margin-bottom: ${vwTablet(140)};
    .margin {
      margin-bottom: ${vwTablet(32)};
    }
    .inputs {
      max-height: ${props => (props.isSent ? '500px' : '5000px')};
    }
  }
  @media (min-width: ${media.desktop}) {
    max-width: ${vwDesktop(588)};
    margin-bottom: ${vwDesktop(140)};
    .margin {
      margin-bottom: ${vwDesktop(32)};
    }
    .inputs {
      max-height: ${props => (props.isSent ? '300px' : '5000px')};
    }
  }
`;

const Radio = styled.div`
  display: flex;
  margin-bottom: ${vw(24)};
  > div {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: ${vw(28)};
    cursor: pointer;
    margin-right: ${vw(32)};
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    height: ${vw(20)};
    width: ${vw(20)};
    opacity: 0;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: ${vw(20)};
    width: ${vw(20)};
    border-radius: 50%;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.color.grey4};
    pointer-events: none;
  }
  span::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${vw(12)};
    height: ${vw(12)};
    border-radius: 50%;
    opacity: 0;
    background-color: ${({ theme }) => theme.color.blue};
  }
  > div input:checked ~ span::after {
    opacity: 1;
  }
  label {
    font-size: ${vw(15)};
    font-weight: 300;
    color: ${({ theme }) => theme.color.black};
    line-height: 1.6;
    text-transform: none;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(24)};
    > div {
      padding-left: ${vwTablet(28)};
      margin-right: ${vwTablet(32)};
    }
    input {
      height: ${vwTablet(20)};
      width: ${vwTablet(20)};
    }
    span {
      height: ${vwTablet(20)};
      width: ${vwTablet(20)};
    }
    span::after {
      width: ${vwTablet(12)};
      height: ${vwTablet(12)};
    }
    label {
      font-size: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(24)};
    > div {
      padding-left: ${vwDesktop(28)};
      margin-right: ${vwDesktop(32)};
    }
    input {
      height: ${vwDesktop(20)};
      width: ${vwDesktop(20)};
    }
    span {
      height: ${vwDesktop(20)};
      width: ${vwDesktop(20)};
    }
    span::after {
      width: ${vwDesktop(12)};
      height: ${vwDesktop(12)};
    }
    label {
      font-size: ${vwDesktop(16)};
    }
  }
`;
