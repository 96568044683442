import React from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Details = props => {
  const { item } = props;
  return (
    <Root>
      {item.updates && (
        <>
          <h4 className="blue">Latest Updates</h4>
          <p className="body-1">{item.updates}</p>
        </>
      )}
      <h4>Address</h4>
      <p className="body-1">{item.address}</p>
      {item.residential && (
        <>
          <h4>Number of Homes</h4>
          <p className="body-1">{item.residential}</p>
        </>
      )}
      {item.commercial && (
        <>
          <h4>Commercial Space</h4>
          <p className="body-1">{item.commercial}</p>
        </>
      )}
      <h4>Completion Date</h4>
      <p className="body-1">{item.completion}</p>
      <h4>Features</h4>
      <ul>
        {item.features.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Root>
  );
};

export default Details;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(40)} ${vw(20)};
  margin-top: ${vw(40)};
  h4 {
    color: ${({ theme }) => theme.color.grey3};
    margin-bottom: ${vw(8)};
  }
  .blue {
    color: ${({ theme }) => theme.color.blue};
  }
  p {
    margin-bottom: ${vw(32)};
  }
  li {
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    color: ${({ theme }) => theme.color.black};
    list-style-type: none;
    display: flex;
    &::before {
      content: '•';
      color: ${({ theme }) => theme.color.black};
      margin-right: ${vw(10)};
      font-weight: normal;
    }
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(60)};
    margin-top: ${vwTablet(80)};
    h4 {
      margin-bottom: ${vwTablet(8)};
    }
    p {
      margin-bottom: ${vwTablet(32)};
    }
    li {
      font-size: ${vwTablet(16)};
      &::before {
        margin-right: ${vwTablet(10)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    margin-top: ${vwDesktop(140)};
    background-color: transparent;
    max-width: ${vwDesktop(282)};
    h4 {
      margin-bottom: ${vwDesktop(8)};
    }
    p {
      margin-bottom: ${vwDesktop(32)};
    }
    li {
      font-size: ${vwDesktop(16)};
      &::before {
        margin-right: ${vwDesktop(10)};
      }
    }
  }
`;
