import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SubButton from 'components/SubButton';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Card = props => {
  const { item, mobileVisible, tabletVisible } = props;
  return (
    <Root
      soldOut={item.soldOut}
      mobileVisible={mobileVisible}
      tabletVisible={tabletVisible}
    >
      <div className="overlay">
        <span className="gradient" />
        <SubButton white>View Community</SubButton>
      </div>
      <img src={item.image} alt={item.title} />
      <div>
        <h4>{item.status}</h4>
        <h3>{item.title}</h3>
        <p className="body-1">{item.tagline}</p>
        <div className="button">
          <SubButton>View Community</SubButton>
        </div>
      </div>
    </Root>
  );
};

Card.propTypes = {
  item: PropTypes.object,
};

export default Card;

const Root = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.grey5};
  background-color: white;
  margin-bottom: ${vw(40)};
  color: ${({ theme }) => theme.color.black};
  display: ${props => (props.mobileVisible ? 'block' : 'none')};
  img {
    width: 100%;
    height: ${vw(200)};
    object-fit: cover;
  }
  h4 {
    color: ${props =>
      props.soldOut ? props.theme.color.grey3 : props.theme.color.blue};
  }
  h3 {
    padding: ${vw(8)} 0;
  }
  p {
    margin-bottom: ${vw(12)};
  }
  > div {
    padding: ${vw(16)};
  }
  .overlay {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(310)};
    margin-bottom: ${vwTablet(40)};
    display: ${props => (props.tabletVisible ? 'block' : 'none')};
    img {
      height: ${vwTablet(240)};
    }
    h3 {
      padding: ${vwTablet(12)} 0;
    }
    p {
      margin-bottom: ${vwTablet(16)};
    }
    > div {
      padding: ${vwTablet(16)} ${vwTablet(20)};
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(382)};
    min-height: ${vwDesktop(456)};
    margin-bottom: ${vwDesktop(14)};
    display: block;
    transition: 0.3s ease;
    position: relative;
    img {
      height: ${vwDesktop(280)};
    }
    h3 {
      padding: ${vwDesktop(12)} 0;
    }
    p {
      margin-bottom: 0;
    }
    > div {
      padding: ${vwDesktop(20)} ${vwDesktop(32)};
    }
    .button {
      display: none;
    }
    &:hover {
      border-color: ${({ theme }) => theme.color.grey3};
    }
    &:hover .overlay {
      opacity: 1;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: ${vwDesktop(280)};
      pointer-events: none;
      z-index: 2;
      opacity: 0;
      transition: 0.3s ease;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .gradient {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.4)
        );
        mix-blend-mode: multiply;
        position: absolute;
        top: 0;
        left: 0;
      }
      a {
        display: block;
      }
    }
  }
`;
