import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const social = [
  {
    icon: require('src/assets/images/icons/fb.svg'),
    link: 'https://www.facebook.com/THINDproperties/',
  },
  {
    icon: require('src/assets/images/icons/twitter.svg'),
    link: 'https://twitter.com/thindproperties',
  },
  {
    icon: require('src/assets/images/icons/instagram.svg'),
    link: 'https://www.instagram.com/thind.properties/',
  },
];

const mainLinks = [
  {
    title: 'Communities',
    to: '/communities',
  },
  {
    title: 'Construction',
    to: '/construction',
  },
  {
    title: 'Philanthropy',
    to: '/philanthropy',
  },
  {
    title: 'Entertainment',
    to: '/entertainment',
  },
  {
    title: 'About',
    to: '/about',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
];

const Footer = () => {
  return (
    <Root>
      <Container>
        <img
          src={require('src/assets/images/logos/white.svg')}
          alt="Thind"
          className="logo"
        />
        <div className="desktop-wrapper">
          <a
            href="https://www.google.com/maps/place/Thind+Properties+Ltd./@49.2324815,-123.0131036,17z/data=!3m1!4b1!4m5!3m4!1s0x548676a5c62355bf:0xbbd12c646744bd5!8m2!3d49.2324815!4d-123.0109096"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="address">
              Thind Properties Ltd.
              <br />
              Unit 700 – 4211 Kingsway
              <br />
              Burnaby, BC V5H 1Z6
            </p>
          </a>
          <div>
            <p>
              <span>T</span>
              <a href="tel:604-451-7780">604.451.7780</a>
            </p>
            <p>
              <span>F</span>
              604.451.7740
            </p>
            <p>
              <span>E</span>
              <a href="mailto:info@thind.ca">info@thind.ca</a>
            </p>
          </div>
        </div>
      </Container>
      <Wrapper>
        {mainLinks.map((item, index) => (
          <Link to={item.to} key={index} className="desktop-link">
            {item.title}
          </Link>
        ))}
        <Link to="/homeownercare">Homeowner Care</Link>
        <Social>
          {social.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img src={item.icon} alt="" />
            </a>
          ))}
        </Social>
      </Wrapper>
      <Bottom>
        <SocialDesktop>
          {social.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img src={item.icon} alt="" />
            </a>
          ))}
        </SocialDesktop>
        <Disclaimer>
          © 2021 Thind Properties Ltd. All Rights Reserved.{' '}
          <Link to="/privacy">Terms of Use &amp; Privacy Policy</Link>
        </Disclaimer>
      </Bottom>
    </Root>
  );
};

export default Footer;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.grey1};
  padding: ${vw(60)} ${vw(20)} ${vw(32)};
  color: white;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(60)} ${vwTablet(30)} ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(80)} ${vwDesktop(120)} ${vwDesktop(24)};
  }
  p {
    font-size: ${vw(13)};
    font-weight: 300;
    line-height: 1.62;
    span {
      font-weight: 500;
      margin-right: 3px;
    }
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(14)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(14)};
      line-height: 1.57;
    }
  }
  .logo {
    margin-bottom: ${vw(24)};
    width: ${vw(123)};
    height: ${vw(32)};
    @media (min-width: ${media.tablet}) {
      order: 2;
      margin-bottom: 0;
      width: ${vwTablet(153)};
      height: ${vwTablet(40)};
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(191)};
      height: ${vwDesktop(50)};
    }
  }
  .address {
    margin-bottom: ${vw(24)};
    @media (min-width: ${media.tablet}) {
      margin-bottom: 0;
      margin-right: ${vwTablet(82)};
    }
    @media (min-width: ${media.desktop}) {
      margin-right: ${vwDesktop(144)};
    }
  }
  a {
    color: white;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  .desktop-wrapper {
    @media (min-width: ${media.tablet}) {
      display: flex;
      order: 1;
    }
  }
  .desktop-link {
    display: none;
    @media (min-width: ${media.desktop}) {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: ${vw(40)} 0 ${vw(24)};
  margin-bottom: ${vw(24)};
  a {
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(60)} 0 ${vwTablet(24)};
    margin-bottom: ${vwTablet(24)};
    a {
      font-size: ${vwTablet(16)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(140)} 0 ${vwDesktop(24)};
    margin-bottom: ${vwDesktop(24)};
    a {
      font-size: ${vwDesktop(14)};
    }
  }
`;

const Social = styled.div`
  display: flex;
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  img {
    margin-left: ${vw(20)};
    width: ${vw(20)};
    height: ${vw(20)};
    @media (min-width: ${media.tablet}) {
      margin-left: ${vwTablet(20)};
      width: ${vwTablet(20)};
      height: ${vwTablet(20)};
    }
    @media (min-width: ${media.desktop}) {
      margin-left: 0;
      margin-right: ${vwDesktop(16)};
      width: ${vwDesktop(16)};
      height: ${vwDesktop(16)};
    }
  }
`;

const SocialDesktop = styled(Social)`
  display: none;
  @media (min-width: ${media.desktop}) {
    display: block;
  }
`;

const Disclaimer = styled.div`
  font-size: ${vw(11)};
  font-weight: 300;
  line-height: 1.64;
  text-align: center;
  a {
    text-decoration: underline;
    display: block;
  }
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(12)};
    line-height: 1.5;
    a {
      display: inline-block;
    }
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(12)};
  }
`;

const Container = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const Bottom = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`;
