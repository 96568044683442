import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Quote from 'components/Quote';
import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Overview = () => {
  return (
    <Root>
      <Container>
        <h4>Overview</h4>
        <h3>Experience and Expertise</h3>
        <p className="body-1 description">
          As a developer and builder of some of the region’s most recognizable
          projects, we have a level of experience and expertise that is renowned
          in the industry. This capacity allows us to provide a level of service
          that encompasses every stage of the construction process. From project
          feasibility analysis, planning, scheduling, execution, and after
          marketing warranties, Thind Construction can provide you with a
          complete construction solution.
        </p>
        <Quote
          text="Because we also build for ourselves, we understand the demands of our clients and have developed a construction system that is efficient and sensitive to our clients’ needs and requirements."
          margin
        />
      </Container>
      <Desktop>
        <Wrapper>
          <h3>Hands-On Support</h3>
          <img
            src={require('src/assets/images/construction/breaking-ground-construction.jpg')}
            alt="Breaking Ground"
          />
          <p className="body-1">
            With an average of 450 homes built annually, we are one of Greater
            Vancouver’s leading new home developers and builders. This
            experience gives us a unique insight and a natural understanding
            that helps guide you through the development and construction
            process.
          </p>
        </Wrapper>
        <Parallax y={['80px', '-30px']}>
          <ParallaxImage
            src={require('src/assets/images/construction/breaking-ground-construction.jpg')}
            alt="Breaking Ground"
            className="desktop-image"
          />
        </Parallax>
      </Desktop>
    </Root>
  );
};

export default Overview;

const Root = styled.div`
  margin-bottom: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(140)};
    .description {
      max-width: ${vwTablet(536)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
    .description {
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Container = styled.div`
  padding: 0 ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  img {
    width: 100%;
    margin: ${vw(8)} 0;
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(90)} ${vwTablet(128)} ${vwTablet(90)} ${vwTablet(40)};
    img {
      display: none;
    }
    h3 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(159)};
    padding-right: ${vwDesktop(264)};
    padding-bottom: ${vwDesktop(159)};
    padding-left: ${vwDesktop(60)};
    h3 {
      margin-bottom: ${vwDesktop(24)};
    }
  }
`;

const Desktop = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    align-items: center;
    padding: 0 ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
  .desktop-image {
    display: none;
    @media (min-width: ${media.tablet}) {
      display: block;
      width: ${vwTablet(312)};
      height: ${vwTablet(257)};
      object-fit: cover;
      margin-left: ${vwTablet(-88)};
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(486)};
      height: ${vwDesktop(400)};
      /* margin-left: 0; */
      margin-left: ${vwDesktop(-204)};
    }
  }
`;
