import React, { useState } from 'react';
import styled from 'styled-components';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Select = props => {
  const [activeSelect, setActiveSelect] = useState('');

  const handleChange = e => setActiveSelect(e.target.value);

  const { name, label, options, id, required } = props;
  return (
    <Root>
      <label htmlFor={id}>{label}</label>
      <select name={name} id={id} required={required} onChange={handleChange}>
        <option value=""></option>
        {options.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
      <Pseudo>
        {activeSelect}
        <img
          src={require('src/assets/images/icons/down-arrow-black.svg')}
          alt="Select"
        />
      </Pseudo>
    </Root>
  );
};

export default Select;

const Pseudo = styled.div`
  display: flex;
  align-items: center;
  height: ${vw(48)};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.grey6};
  pointer-events: none;
  padding: 0 ${vw(16)};
  font-size: ${vw(16)};
  font-weight: 300;
  line-height: 1.63;
  color: ${({ theme }) => theme.color.grey2};
  img {
    position: absolute;
    top: 50%;
    right: ${vw(20)};
    transform: translateY(-50%);
    width: ${vw(17)};
    height: ${vw(9)};
    z-index: 2;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(48)};
    padding: 0 ${vwTablet(16)};
    font-size: ${vwTablet(16)};
    img {
      right: ${vwTablet(20)};
      width: ${vwTablet(17)};
      height: ${vwTablet(9)};
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(48)};
    padding: 0 ${vwDesktop(16)};
    font-size: ${vwDesktop(16)};
    img {
      right: ${vwDesktop(20)};
      width: ${vwDesktop(17)};
      height: ${vwDesktop(9)};
    }
  }
`;

const Root = styled.div`
  margin-bottom: ${vw(24)};
  position: relative;
  select {
    width: 100%;
    height: ${vw(48)};
    background-color: ${({ theme }) => theme.color.grey6};
    box-shadow: none;
    border-radius: 0;
    border: 0;
    opacity: 0;
  }
  select:focus ~ ${Pseudo} {
    border: 1px solid ${({ theme }) => theme.color.blue};
    outline: none;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(24)};
    select {
      padding: 0 ${vwTablet(16)};
      font-size: ${vwTablet(16)};
      height: ${vwTablet(48)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(24)};
    select {
      padding: 0 ${vwDesktop(16)};
      font-size: ${vwDesktop(16)};
      height: ${vwDesktop(48)};
    }
  }
`;
