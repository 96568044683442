import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vw, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import { menu, subMenu } from 'src/data/menu';

const MobileMenu = props => {
  const { isOpen, handleMenu, location } = props;
  return (
    <Root isOpen={isOpen}>
      {menu.map((item, index) => (
        <Main
          key={index}
          onClick={handleMenu}
          active={location.includes(item.to)}
        >
          <Link to={item.to}>{item.title}</Link>
        </Main>
      ))}
      {subMenu.map((item, index) => (
        <Sub
          key={index}
          onClick={handleMenu}
          active={location.includes(item.to)}
        >
          <Link to={item.to}>{item.title}</Link>
        </Sub>
      ))}
    </Root>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  handleMenu: PropTypes.func,
  location: PropTypes.string,
};

export default MobileMenu;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: 0.3s ease;
  z-index: 5;
  padding-top: ${vw(98)};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(194)};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;

const Main = styled.h2`
  margin-bottom: ${vw(24)};
  a {
    color: ${props =>
      props.active ? props.theme.color.blue : props.theme.color.black};
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(60)};
  }
`;

const Sub = styled.h3`
  margin-bottom: ${vw(24)};
  a {
    color: ${props =>
      props.active ? props.theme.color.blue : props.theme.color.black};
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(60)};
  }
`;
