import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Card from '../elements/Card';

import data from 'src/data/communities';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Properties = () => {
  const mobileAmount = 6;
  const tabletAmount = 8;

  const [isAllVisible, setIsAllVisible] = useState(false);

  const handleClick = () => setIsAllVisible(true);

  return (
    <Root>
      <Container>
        {Object.values(data).map((item, index) => (
          <StyledLink
            to={`/communities/${item.slug}`}
            mobilevisible={(
              index + 1 <= mobileAmount || isAllVisible
            ).toString()}
            tabletvisible={(
              index + 1 <= tabletAmount || isAllVisible
            ).toString()}
            key={index}
          >
            <Card
              item={item}
              key={index}
              mobileVisible={index + 1 <= mobileAmount || isAllVisible}
              tabletVisible={index + 1 <= tabletAmount || isAllVisible}
            />
          </StyledLink>
        ))}
      </Container>
      {!isAllVisible && (
        <ViewAll onClick={handleClick}>
          <p>View All</p>
          <img
            src={require('src/assets/images/icons/down-arrow-blue.svg')}
            alt="View All"
          />
        </ViewAll>
      )}
    </Root>
  );
};

export default Properties;

const Root = styled.div`
  margin-top: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(140)};
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(80)};
  }
`;

const ViewAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: ${vw(16)};
    font-weight: normal;
    line-height: 1.63;
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(11)};
  }
  img {
    width: ${vw(23)};
    height: ${vw(14)};
  }
  @media (min-width: ${media.tablet}) {
    p {
      font-size: ${vwTablet(16)};
      margin-bottom: ${vwTablet(11)};
    }
    img {
      width: ${vwTablet(23)};
      height: ${vwTablet(14)};
    }
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
`;

const Container = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: ${media.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${vwDesktop(26)};
  }
`;

const StyledLink = styled(Link)`
  display: ${props => (props.mobilevisible === 'true' ? 'block' : 'none')};
  @media (min-width: ${media.tablet}) {
    display: ${props => (props.tabletvisible === 'true' ? 'block' : 'none')};
  }
  @media (min-width: ${media.desktop}) {
    display: block;
  }
`;
