import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import SubButton from 'components/SubButton';
import ParallaxImage from 'components/ParallaxImage';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Leadership = () => {
  return (
    <Root>
      <Wrapper>
        <h4>Leadership</h4>
        <h2>Daljit Thind</h2>
        <img
          src={require('src/assets/images/home/thind-homepage.jpg')}
          alt="Daljit Thind"
          className="mobile-photo"
        />
        <Tagline>Founder &amp; CEO</Tagline>
        <p className="body-1">
          For over 20 years, Daljit Thind has been changing the Vancouver real
          estate landscape one quality home at a time.{' '}
          <span className="desktop">
            Today, Daljit is one of Vancouver’s premier real estate developers
            and is nationally and internationally renowned for his philanthropy.
          </span>
          <span className="desktop">
            <br />
            <br />
            As Founder and CEO of Thind, he oversees every aspect of his
            signature property developments. One of British Columbia’s first
            Indo-Canadian developers to develop high-rises, Daljit constantly
            strives for architectural innovation. He continues to push the
            envelope in environmentally-friendly building practices and superior
            customer service.
          </span>
        </p>
        <SubButton to="/about">About Us</SubButton>
        <h3>Community-Minded Entrepreneur</h3>
        <p className="body-1 mobile">
          <span className="mobile-photo">
            Today, Daljit is one of Vancouver’s premier real estate developers
            and is nationally and internationally renowned for his philanthropy.
          </span>
          <span className="desktop">
            Along with building communities, Daljit is committed to making the
            neighbourhoods he builds in as rewarding to live in as his homes. By
            supporting local charities and other community-based initiatives, he
            is dedicated to investing in, and building a future that everyone
            will benefit from.
            <br />
            <br />
            In addition to sponsoring local initiatives, Daljit has also helped
            to build schools near his hometown in Northern India, providing
            quality education to over 1,200 students.
          </span>
        </p>
        <SubButton to="/philanthropy">Philanthropy</SubButton>
        <p className="body-1 margin">
          Along with a passion for creating innovative communities, Daljit’s
          creativity is equally active in the film industry.
        </p>
        <SubButton to="/entertainment">Entertainment</SubButton>
      </Wrapper>
      <div className="mobile-hidden">
        <Parallax y={['80px', '-30px']}>
          <ParallaxImage
            src={require('src/assets/images/home/thind-homepage.jpg')}
            alt="Daljit Thind"
            className="desktop-photo"
          />
          <h4 className="grey">Founder &amp; CEO</h4>
        </Parallax>
      </div>
    </Root>
  );
};

export default Leadership;

const Root = styled.div`
  @media (min-width: ${media.tablet}) {
    display: flex;
    margin-bottom: ${vwTablet(140)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
    padding: 0 ${vwDesktop(120)};
  }
  img {
    width: 100%;
    margin: ${vw(32)} 0 ${vw(8)};
    @media (min-width: ${media.tablet}) {
      width: ${vwTablet(284)};
      height: ${vwTablet(350)};
      margin: ${vwTablet(140)} 0 0;
    }
    @media (min-width: ${media.desktop}) {
      width: ${vwDesktop(486)};
      height: ${vwDesktop(600)};
      margin: ${vwDesktop(120)} 0 0;
    }
  }
  h4 {
    font-size: ${vw(12)};
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: ${vw(2)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
    @media (min-width: ${media.tablet}) {
      font-size: ${vwTablet(12)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(12)};
    }
    @media (min-width: ${media.desktop}) {
      font-size: ${vwDesktop(12)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(12)};
    }
  }
  .grey {
    color: ${({ theme }) => theme.color.grey3};
    margin-top: ${vwTablet(8)};
    display: none;
    @media (min-width: ${media.tablet}) {
      display: block;
    }
    @media (min-width: ${media.desktop}) {
      margin-top: ${vwDesktop(8)};
    }
  }
  p {
    margin-bottom: ${vw(8)};
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(8)};
    }
    @media (min-width: ${media.desktop}) {
      margin-bottom: ${vwTablet(8)};
    }
  }
  h3 {
    margin: ${vw(42)} 0 ${vw(16)};
    display: none;
    @media (min-width: ${media.tablet}) {
      margin: ${vwTablet(42)} 0 ${vwTablet(24)};
      display: block;
    }
    @media (min-width: ${media.desktop}) {
      margin: ${vwDesktop(62)} 0 ${vwDesktop(24)};
    }
  }
  .margin {
    margin-top: ${vw(26)};
    @media (min-width: ${media.tablet}) {
      margin-top: ${vwTablet(32)};
    }
    @media (min-width: ${media.desktop}) {
      margin-top: ${vwDesktop(32)};
    }
  }
  .mobile-photo {
    @media (min-width: ${media.tablet}) {
      display: none;
    }
  }
  .desktop-photo {
    display: none;
    @media (min-width: ${media.tablet}) {
      display: block;
    }
  }
  .desktop {
    display: none;
    @media (min-width: ${media.tablet}) {
      display: inline;
    }
  }
  h2 {
    @media (min-width: ${media.tablet}) {
      margin-bottom: ${vwTablet(32)};
    }
    @media (min-width: ${media.desktop}) {
      margin-bottom: ${vwDesktop(32)};
    }
  }
  .mobile-hidden {
    display: none;
    @media (min-width: ${media.tablet}) {
      display: block;
    }
  }
  .mobile {
    margin-top: ${vw(24)};
    @media (min-width: ${media.tablet}) {
      margin-top: 0;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey6};
  padding: ${vw(60)} ${vw(20)};
  color: ${({ theme }) => theme.color.black};
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(596)};
    padding: ${vwTablet(80)} ${vwTablet(172)} ${vwTablet(120)} ${vwTablet(60)};
    margin-right: ${vwTablet(-112)};
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(894)};
    padding-top: ${vwDesktop(120)};
    padding-right: ${vwDesktop(260)};
    padding-bottom: ${vwDesktop(80)};
    padding-left: ${vwDesktop(80)};
    margin-right: ${vwDesktop(-180)};
  }
`;

const Tagline = styled.div`
  text-align: right;
  font-size: ${vw(12)};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: ${vw(2)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.grey3};
  width: 100%;
  margin-bottom: ${vw(32)};
  @media (min-width: ${media.tablet}) {
    display: none;
  }
`;
