export default [
  {
    name: 'Daljit Thind',
    image: require('src/assets/images/home/thind-homepage.jpg'),
    subTitle: 'President & CEO',
    description: [
      'Since 1996, Daljit Thind has been changing the landscape of Vancouver real estate one quality home at a time. Today, Thind is one of Vancouver’s premier real estate developers, well known for his philanthropy in Canada as well as in India and is a true entrepreneur at heart.',

      'As founder and CEO of Thind Properties, he oversees all aspects of his signature developments from start to finish. As one of British Columbia’s first Indo-Canadian developers to develop high-rises, Thind constantly strives for architectural innovation. He continues to push the envelope in environmentally friendly building practices and superior customer service.',
    ],
  },
  {
    name: 'Paul Thind',
    image: require('src/assets/images/about/team/paul-thind.jpg'),
    subTitle: 'Chief Operating Officer',
    description: [
      'Shortly after graduating from the University of British Columbia, Paul joined Thind Properties in 2017. In these past couple of years, he’s had the privilege of working with some of the finest innovators in the industry. With his dedicated team, Paul envisions a market with more affordable, environmental, and sustainable living options throughout the country.',
    ],
  },
  {
    name: 'Bonnie Leung',
    image: require('src/assets/images/about/team/bonnie-leung.jpg'),
    subTitle: 'Vice President - Marketing',
    description: [
      'Bonnie has worked in the real estate industry for nearly 10 years. While attending post-secondary school, Bonnie worked at presentation centres which jump started her career in project marketing. In 2010, Bonnie acquired her Real Estate Licence and since then has been a major contributor to Thind’s growing success. Today, Bonnie takes part in the acquisition to homeowner key turnover and leads the sales and marketing division of Thind Properties’ portfolio.',
    ],
  },
  {
    name: 'Steve Eder',
    image: require('src/assets/images/about/team/steve-eder.jpg'),
    subTitle: 'vice president - construction',
    description: [
      'Steve brings over 30 years of construction experience in the Vancouver market. After operating his own construction company for a decade, Steve switched to working with Vancouver’s largest construction firms. He is a certified PMP and completed the BCIT Construction Management Program. Steve also received his carpentry ticket post-graduation. He has vast experience with large commercial projects and specializes in residential concrete high-rises. Steve transitioned from the construction side as a Site Superintendent to the corporate site as a Project Manager, and has progressed from a Director onto becoming the Vice President of Construction at Thind.',
    ],
  },
  {
    name: 'David Lee',
    image: require('src/assets/images/about/team/david-lee.jpg'),
    subTitle: 'Vice President - accounting',
    description: [
      'Designated as a CPA and CMA for over 25 years, David brings a wealth of knowledge to Thind’s management team. He has more than 30 years of experience in accounting and finance for various major development, construction, and property management companies. Overseeing all aspects of accounting from bank loans, progress draws, to information technology and preparing financial reports, David offers effective solutions to optimizing project operations.',
    ],
  },
  {
    name: 'Brendan Sallis',
    image: require('src/assets/images/about/team/brendan-sallis.jpg'),
    subTitle: 'Vice President - Finance',
    description: [
      'Brendan leads the construction finance and accounting side of Thind. Previously, he spent 8 years in public practice and 12 years at a construction and development company before joining Thind in 2019. In his spare time Brendan plays soccer, coaches youth soccer, and paddles on a dragon boating team.',
    ],
  },
  {
    name: 'Dave Westley',
    image: require('src/assets/images/about/team/dave-westley.jpg'),
    subTitle: 'Vice President - Affordable Housing',
    description: [
      `Dave has been involved in the construction and development industry for over 40 years. His experience as a tradesman, site superintendent, project manager, and development manager brings invaluable knowledge to the Thind team. As part of Daljit Thind's development philosophy—to always leave an area better than when you arrived—Dave leads the creation of new and innovative affordable housing opportunities for the people of British Columbia.`,
    ],
  },
  {
    name: 'Afshin Gharaee',
    image: require('src/assets/images/about/team/afshin-Gharaee.jpg'),
    subTitle: 'Senior Project Manager',
    description: [
      'With over 10 years in the construction industry in the UK and Canada, Afshin possesses solid experience in residential construction and sustainable development. He is a committed and passionate Project Manager who is also responsible for tendering and negotiating trade contracts. Afshin completed his second Master’s Degree in Construction Management from City University of London. He also holds a Master’s Degree in Environmental Engineering and a Bachelor’s Degree in Civil Engineering.',
    ],
  },
  {
    name: 'Lu Tang',
    image: require('src/assets/images/about/team/lu-tang.jpg'),
    subTitle: 'Senior Development Manager',
    description: [
      'Well-versed in design, planning, project management and sustainability with an entrepreneur mindset Lu oversees the planning and development of Thind homes and communities. Lu holds a bachelor’s degree (B. Sc) and a master’s degree (M. Arch) in Architecture. She is the first woman from China granted as a full member of the Architectural Institute of British Columbia with the designation (MAIBC) in 2005. Lu is an avid cyclist and a golfer though she doesn’t golf as much since she picked up cycling. She is passionate about her chosen career and is always open to a coffee down the street.',
    ],
  },
];
