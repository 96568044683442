import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Popup = props => {
  const {
    isPopupOpen,
    closePopup,
    data,
    sliderRef,
    setActiveTeamMember,
    nextIndex,
    prevIndex,
  } = props;

  const settings = {
    autoplay: false,
    infinite: true,
    dots: false,
    arrows: false,
    afterChange: index => setActiveTeamMember(index),
  };
  return (
    <Root isOpen={isPopupOpen}>
      <Close
        src={require('src/assets/images/icons/close.svg')}
        alt="Close Popup"
        onClick={closePopup}
      />
      <Slider {...settings} ref={sliderRef}>
        {data.map((item, index) => (
          <Slide key={index}>
            <Desktop>
              <div className="image">
                <img src={item.image} alt={item.name} />
              </div>
              <div className="content">
                <p className="body-1 name">{item.name}</p>
                <h4>{item.subTitle}</h4>
                <Content>
                  {item.description.map((item, index) => (
                    <p key={index} className="body-2">
                      {item}
                    </p>
                  ))}
                </Content>
              </div>
            </Desktop>
          </Slide>
        ))}
      </Slider>
      <Arrows>
        <div onClick={() => sliderRef.current.slickPrev()}>
          <img
            src={require('src/assets/images/icons/arrow-left.svg')}
            alt="Previous"
            className="prev"
          />
          <div className="desktop">
            <p className="body-1">{data[prevIndex()].name}</p>
            <h4>{data[prevIndex()].subTitle}</h4>
          </div>
          <h4 className="mobile">Previous</h4>
        </div>
        <div onClick={() => sliderRef.current.slickNext()}>
          <h4 className="mobile">Next</h4>
          <div className="desktop">
            <p className="body-1">{data[nextIndex()].name}</p>
            <h4>{data[nextIndex()].subTitle}</h4>
          </div>
          <img
            src={require('src/assets/images/icons/arrow-right.svg')}
            alt="Next"
            className="next"
          />
        </div>
      </Arrows>
    </Root>
  );
};

export default Popup;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  pointer-events: ${props => (props.isOpen ? 'all' : 'none')};
  transition: 0.5s ease;
`;

const Close = styled.img`
  position: absolute;
  top: ${vw(20)};
  right: ${vw(20)};
  width: ${vw(16)};
  height: ${vw(16)};
  z-index: 2;
  cursor: pointer;
  @media (min-width: ${media.tablet}) {
    top: ${vwTablet(60)};
    right: ${vwTablet(60)};
    width: ${vwTablet(24)};
    height: ${vwTablet(24)};
  }
  @media (min-width: ${media.desktop}) {
    top: ${vwDesktop(112)};
    right: ${vwDesktop(276)};
    width: ${vwDesktop(24)};
    height: ${vwDesktop(24)};
  }
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  padding: ${vw(36)} ${vw(20)};
  .image {
    width: ${vw(148)};
    height: ${vw(178)};
    margin-bottom: ${vw(8)};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h4 {
    color: ${({ theme }) => theme.color.grey3};
    margin: ${vw(2)} 0 ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(100)} ${vwTablet(60)};
    .image {
      width: ${vwTablet(282)};
      height: ${vwTablet(328)};
      margin-bottom: ${vwTablet(24)};
    }
    h4 {
      margin: ${vwTablet(12)} 0 ${vwTablet(24)};
    }
    .name {
      font-size: ${vwTablet(28)};
      line-height: 1.29;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(160)} ${vwDesktop(324)};
    .image {
      width: ${vwDesktop(282)};
      height: ${vwDesktop(328)};
      margin-bottom: 0;
      margin-right: ${vwDesktop(60)};
    }
    .content {
      max-width: ${vwDesktop(450)};
    }
    h4 {
      margin: ${vwDesktop(12)} 0 ${vwDesktop(24)};
    }
    .name {
      font-size: ${vwDesktop(28)};
      line-height: 1.29;
    }
  }
`;

const Arrows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${vw(20)};
  position: absolute;
  bottom: 10vh;
  width: 100%;
  z-index: 2;
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  h4 {
    color: ${({ theme }) => theme.color.black};
    margin: 0;
  }
  .prev {
    margin-right: ${vw(8)};
  }
  .next {
    margin-left: ${vw(8)};
  }
  .desktop {
    display: none;
  }
  @media (min-height: 800px) and (max-width: 420px) {
    bottom: 20vh;
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
    bottom: ${vwTablet(80)};
    .prev {
      margin-right: ${vwTablet(24)};
    }
    .next {
      margin-left: ${vwTablet(24)};
    }
    img {
      width: ${vwTablet(18)};
      height: ${vwTablet(34)};
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
    h4 {
      color: ${({ theme }) => theme.color.grey3};
    }
    > div:last-of-type {
      text-align: right;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 ${vwDesktop(324)};
    bottom: ${vwDesktop(130)};
    .prev {
      margin-right: ${vwDesktop(24)};
    }
    .next {
      margin-left: ${vwDesktop(24)};
    }
    img {
      width: ${vwDesktop(18)};
      height: ${vwDesktop(34)};
    }
  }
  @media (min-width: 1900px) {
    margin-bottom: ${vwDesktop(-80)};
  }
`;

const Content = styled.div`
  max-height: 30.3vh;
  overflow-y: auto;
  p {
    margin-bottom: ${vw(20)};
    margin-right: ${vw(12)};
  }
  ::-webkit-scrollbar {
    width: ${vw(4)};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.grey6};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grey2};
  }
  @media (min-width: ${media.tablet}) {
    max-height: none;
    p {
      margin-bottom: ${vwTablet(20)};
      margin-right: 0;
      max-width: none;
    }
    ::-webkit-scrollbar {
      width: ${vwTablet(4)};
    }
  }
  @media (min-width: ${media.desktop}) {
    p {
      margin-bottom: ${vwDesktop(20)};
    }
    ::-webkit-scrollbar {
      width: ${vwDesktop(4)};
    }
  }
`;

const Desktop = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
  }
`;
