import React from 'react';
import styled from 'styled-components';

import SubButton from 'components/SubButton';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Privacy = () => {
  return (
    <Root>
      <h2>Terms of Use &amp; Privacy Policy</h2>
      <h4>Terms of Use</h4>
      <p className="body-1">
        The material, images, renderings and information on this website is
        provided on an ‘as is’ basis solely for the reader’s general knowledge.
        The information is not intended to be a comprehensive review of all
        matters and developments concerning Thind Properties, and therefore
        assumes no responsibility and provides no warranty, either express or
        implied, as to its accuracy, completeness or currency. In no event will
        Thind Properties be liable for any special, incidental, indirect or
        consequential damages of any kind, or any damages whatsoever arising out
        of or in connection with the use or performance of this information,
        including without limitation, those resulting from loss of use, data or
        profits, whether or not advised of the possibility of damage. This site
        may contain links to external organizations. We do not control the
        relevance, timeliness, or accuracy of materials produced by other
        organizations, nor do we endorse their views, products or services. Your
        access and use of this website constitutes your agreement to be governed
        by the contents of the disclaimer set forth above.
      </p>
      <h4>Privacy Policy</h4>
      <p className="body-1">
        Thind Properties and each of our affiliates and partnerships is
        committed to maintaining the accuracy, confidentiality and security of
        your personal information. This Privacy Policy describes the personal
        information that Thind Properties collects from or about you, how we use
        that information, and to whom we disclose that information.
      </p>
      <h4>collecting personal information</h4>
      <p className="body-1">
        Thind Properties collects personal information to better serve your
        needs, whether purchasing products and services from us or obtaining
        service or warranty work. In order to provide you with our wide variety
        of services, products and promotions, we may collect the following
        information:
      </p>
      <ul>
        <li>
          Contact and mailing information such as name, address, telephone
          numbers, fax numbers and email addresses.
        </li>
        <li>
          The plans, specifications and other particulars about the home that is
          being built for you.
        </li>
        <li>Municipal and legal descriptions for your home.</li>
        <li>
          Any other relevant information necessary to provide you with our
          products and services.
        </li>
        <li>
          We may need to collect other personal information depending on the
          circumstance, but we will ask you directly and ensure that you agree.
        </li>
      </ul>
      <h4>personal information security</h4>
      <p className="body-1">
        Like you, Thind Properties values our personal information. Part of
        valuing your personal information is making sure that it is protected
        and kept confidential. We achieve this by:
      </p>
      <ul>
        <li>Having our employees sign a confidentiality agreement.</li>
        <li>
          Having up to date computer security such as passwords, encryption and
          firewalls.
        </li>
        <li>
          Where third parties are hired to assist us with providing products and
          services to you for your home, we have them sign a privacy and
          confidentiality agreement to ensure that your personal information is
          protected by them.
        </li>
      </ul>
      <h4>personal information updating</h4>
      <p className="body-1">
        Thind Properties wants to ensure that your personal information is
        always correct up to date. If your personal information changes or is
        incorrect, updates can be made by contacting your representative with
        the change and any other information you feel is necessary.
        <br />
        <br />
        We will keep your information as long as is necessary to continue to
        meet your customer needs and any legal requirements we may have.
        However, once your personal information no longer needed, we will
        destroy it.
      </p>
      <h4>personal data</h4>
      <p className="body-1">
        We only collect Personal Data from you, such as first and last name,
        phone number, e-mail, when you register to receive information. We
        collect data about your activities that does not personally or directly
        identify you when you visit our website, or the websites and online
        services where we display advertisements. This information may include
        the content you view, the date and time that you view this content, the
        products you purchase, or your location information associated with your
        IP address. We use the information we collect to serve you more relevant
        advertisements (referred to as “Retargeting”). We collect information
        about where you saw the ads we serve you and what ads you clicked on.
        <br />
        <br />
        As you browse the website, online ad networks we work with may place
        anonymous cookies on your computer, and use similar technologies, in
        order to understand your interests based on your (anonymous) online
        activities, and thus to tailor more relevant ads to you. If you do not
        wish to receive such tailored advertising, you can visit this page to
        opt out of most companies that engage in such advertising. (This will
        not prevent you from seeing ads; the ads simply will not be delivered
        through these targeting methods.)
        <br />
        <br />
        We do not target ads to you based on sensitive personal data, such as
        information related to race or ethnic origins, political opinions,
        religious beliefs or other beliefs of a similar nature, trade union
        membership, physical or mental health or condition or sexual life. We
        may target ads based on data provided by Advertisers or partners alone
        or in combination with the data we collect ourselves. Any data used to
        serve targeted advertisements is de-identified and is not used to
        personally or directly identify you. In order to allow us to reach the
        best inventory online, we work with third-party advertising companies
        (our “Advertising Partners”) to help us recognize you and serve relevant
        advertisements to you when you visit a website or online service in
        their network. We also work with Advertising Partners who help us
        recognize you across different devices in order to show you relevant
        advertisements. Our Advertising Partners may collect information about
        your activities on our website, and other websites or online services in
        their networks. We also work with third party companies to assist us
        with website analytics such as evaluating the use and operation of our
        website so that we can continue to enhance the website and our services.
        <br />
        <br />
        We and our third-party partners use session cookies and persistent
        cookies to make it easier for you to navigate and enhance the experience
        of our site, and to monitor and evaluate our website’s operation and
        use.
        <br />
        <br />
        To modify or delete your personal information, or to opt out of future
        communications, please contact us at:
        <br />
        <br />
        <span>Thind Properties Ltd.</span>
        <br />
        Unit 700 – 4211 Kingsway
        <br />
        Burnaby, BC V5H 1Z6
        <br />
        <br />
        Email:{' '}
        <a href="mailto:info@thind.ca" className="link">
          info@thind.ca
        </a>
      </p>
      <Space />
      <SubButton to="/" blue>
        Back to Homepage
      </SubButton>
    </Root>
  );
};

export default Privacy;

const Root = styled.div`
  padding: ${vw(154)} ${vw(20)} ${vw(80)};
  color: ${({ theme }) => theme.color.black};
  h4 {
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
    margin-top: ${vw(40)};
  }
  .link {
    color: ${({ theme }) => theme.color.black};
  }
  span {
    font-weight: 500;
  }
  h2 {
    margin-bottom: ${vw(60)};
  }
  ul {
    margin-top: ${vw(16)};
  }
  li {
    list-style-type: none;
    font-size: ${vw(15)};
    font-weight: 300;
    line-height: 1.6;
    display: flex;
    &::before {
      content: '∙';
      font-weight: normal;
      margin-right: ${vw(10)};
    }
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(234)} ${vwTablet(60)} ${vwTablet(140)};
    h4 {
      margin-bottom: ${vwTablet(12)};
      margin-top: ${vwTablet(40)};
    }
    h2 {
      margin-bottom: ${vwTablet(60)};
    }
    ul {
      margin-top: ${vwTablet(16)};
    }
    li {
      font-size: ${vwTablet(16)};
      &::before {
        margin-right: ${vwTablet(10)};
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(214)} ${vwDesktop(324)} ${vwDesktop(140)};
    h4 {
      margin-bottom: ${vwDesktop(12)};
      margin-top: ${vwDesktop(40)};
    }
    h2 {
      margin-bottom: ${vwDesktop(60)};
    }
    ul {
      margin-top: ${vwDesktop(16)};
    }
    li {
      font-size: ${vwDesktop(16)};
      &::before {
        margin-right: ${vwDesktop(10)};
      }
    }
  }
`;

const Space = styled.div`
  padding-top: ${vw(60)};
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(80)};
  }
`;
