import React from 'react';
import styled from 'styled-components';

import Quote from 'components/Quote';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const AffordableHousing = () => {
  return (
    <Root>
      <Wrapper>
        <h4>affordable housing</h4>
        <h3>A Catalyst for Change</h3>
        <p className="body-1">
          Part of Daljit’s legacy of giving is being a catalyst for change in
          his own industry. Working with provincial and local governmental
          leaders as well as non-profit organizations, Daljit was instrumental
          in helping families in Burnaby in need of safe and affordable housing.
        </p>
        <Quote text="“Thind Properties recognized the crisis in affordable housing in the Metrotown area, and took the initiative to develop a model where a team consisting of private developers, the city, the Province (BC Housing) and a selected non-profit could mitigate the pain caused to people by ‘demovictions.’ This is in keeping with my pledge to always leave a neighbourhood a better place to live when we finish a project.”" />
        <h4 className="black">— Daljit Thind</h4>
        <img
          src={require('src/assets/images/philanthropy/highline-affordable-housing-new.jpg')}
          alt="Highline"
          className="property"
        />
      </Wrapper>
    </Root>
  );
};

export default AffordableHousing;

const Root = styled.div`
  .black {
    color: ${({ theme }) => theme.color.black};
    margin: ${vw(24)} 0 ${vw(60)};
  }
  .property {
    width: 100%;
    height: ${vw(160)};
    object-fit: cover;
    object-position: 70%;
  }
  .desktop-image {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(140)};
    .black {
      margin: ${vwTablet(32)} 0 ${vwTablet(100)} ${vwTablet(84)};
    }
    .property {
      height: ${vwTablet(240)};
    }
    .mobile-image {
      display: none;
    }
    .desktop-image {
      display: block;
      width: ${vwTablet(312)};
      height: ${vwTablet(257)};
      object-fit: cover;
      margin-right: ${vwTablet(-88)};
      z-index: 2;
    }
    h3 {
      margin-bottom: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(140)};
    .black {
      margin: ${vwDesktop(32)} 0 ${vwDesktop(100)} ${vwDesktop(102)};
    }
    .property {
      height: ${vwDesktop(300)};
    }
    .desktop-image {
      width: ${vwDesktop(486)};
      height: ${vwDesktop(360)};
      margin-right: ${vwDesktop(-180)};
    }
    h3 {
      margin-bottom: ${vwDesktop(24)};
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vw(20)} ${vw(80)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)} ${vwTablet(80)};
    > p {
      max-width: ${vwTablet(534)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 0 ${vwDesktop(80)};
    > p {
      max-width: ${vwDesktop(588)};
    }
  }
`;
