import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import SubButton from 'components/SubButton';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/homeProperties';

const Properties = props => {
  const [activeImage, setActiveImage] = useState(0);

  const handleHover = index => {
    setActiveImage(index);
    let images = document
      .getElementById('grid')
      .getElementsByClassName('image');
    for (let i = 0; i < images.length; i++) {
      images[i].style.opacity = 0;
    }
  };

  const handleHoverLeave = () => {
    let images = document
      .getElementById('grid')
      .getElementsByClassName('image');
    for (let i = 0; i < images.length; i++) {
      images[i].style.opacity = 1;
    }
  };

  return (
    <Root>
      <h2>Our Communities</h2>
      <Grid id="grid" style={{ gridGap: '4px' }}>
        {data.map((item, index) => (
          <Property
            key={index}
            image={item.image}
            index={index}
            activeImage={index === activeImage}
            className="image"
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={handleHoverLeave}
            onClick={() => props.history.push(item.to)}
          >
            <img src={item.image} alt={item.title} />
            <div className="gradient" />
            <h3>{item.title}</h3>
            <h4>{item.subTitle}</h4>
            <SubButton white>
              View {item.viewall ? 'All' : 'Community'}
            </SubButton>
          </Property>
        ))}
        {data.map((item, index) => (
          <Static
            image={item.image}
            key={index}
            active={index === activeImage}
          />
        ))}
        <StaticGrid image={require('src/assets/images/home/static-grid.png')} />
      </Grid>
    </Root>
  );
};

export default withRouter(Properties);

const Root = styled.div`
  padding: ${vw(80)} 0;
  text-align: center;
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(140)} 0;
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(140)} 0;
  }
`;

const Grid = styled.div`
  margin-top: ${vw(32)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 4px !important;
    margin-top: ${vwTablet(32)};
  }
  @media (min-width: ${media.desktop}) {
    display: grid;
    grid-template-rows: ${vwDesktop(320)} ${vwDesktop(320)};
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${vwDesktop(6)} !important;
    margin-top: ${vwDesktop(32)};
  }
`;

const Property = styled.div`
  width: ${vw(320)};
  height: ${vw(200)};
  margin-bottom: ${vw(4)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  position: relative;
  h4,
  button {
    display: none;
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(382)};
    height: ${vwTablet(256)};
    margin-bottom: 0;
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(476)};
    height: 100%;
    padding-bottom: 0;
    justify-content: center;
    transition: 0.3s ease;
    transition-delay: ${props =>
      props.activeImage ? '0' : props.index * 0.07}s;
    h4,
    button {
      display: block;
    }
    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    h4 {
      opacity: 0;
      font-size: ${vwDesktop(12)};
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: ${vwDesktop(2)};
      text-transform: uppercase;
      margin: 0;
      position: absolute;
      top: ${vwDesktop(190)};
      left: 50%;
      transform: translateX(-50%);
    }
    button {
      position: absolute;
      left: 50%;
      bottom: ${vwDesktop(42)};
      transform: translateX(-50%);
    }
    &::after {
      content: '';
      width: ${vwDesktop(452)};
      height: ${vwDesktop(296)};
      position: absolute;
      top: ${vwDesktop(12)};
      left: ${vwDesktop(12)};
      border: 1px solid ${({ theme }) => theme.color.blue};
      opacity: 0;
      transition: 0.3s ease;
    }
    &:hover::after,
    &:hover h4,
    &:hover a,
    &:hover button {
      opacity: 1;
    }
    &:hover {
      opacity: 1 !important;
    }
    &:hover img {
      opacity: 0;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease;
    z-index: 1;
  }
  h3,
  h4,
  a,
  button {
    z-index: 2;
  }
  a,
  button {
    opacity: 0;
    transition: 0.3s ease;
  }
  .gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    );
    mix-blend-mode: multiply;
    z-index: 2;
  }
`;

// const Wrapper = styled.div`
//   margin-top: ${vw(38)};
//   display: flex;
//   justify-content: center;
//   @media (min-width: ${media.tablet}) {
//     margin-top: ${vwTablet(32)};
//   }
//   @media (min-width: ${media.desktop}) {
//     margin-top: ${vwDesktop(32)};
//   }
// `;

const Static = styled.div`
  width: ${`calc(100% + ${vwDesktop(6)})`};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  z-index: -2;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: 0.3s ease;
  display: none;
  @media (min-width: ${media.desktop}) {
    display: block;
  }
`;

const StaticGrid = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${props => props.image});
  background-size: 100%;
  background-position: center;
  z-index: -1;
  display: none;
  @media (min-width: ${media.desktop}) {
    display: block;
  }
`;
