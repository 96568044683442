export const menu = [
  {
    title: 'Communities',
    to: '/communities',
  },
  {
    title: 'Construction',
    to: '/construction',
  },
  {
    title: 'Philanthropy',
    to: '/philanthropy',
  },
  {
    title: 'Entertainment',
    to: '/entertainment',
  },
];

export const subMenu = [
  {
    title: 'About',
    to: '/about',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
  {
    title: 'Homeowner Care',
    to: '/homeownercare',
    hideOnDesktop: true,
  },
];
