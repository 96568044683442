import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ThankYou = () => {
  return (
    <Root>
      <h1>Thank You</h1>
      <p>
        Your message has been received and one of our team members will be in
        touch shortly.
      </p>
      <Button to="/" blue>
        Back to Homepage
      </Button>
    </Root>
  );
};

export default ThankYou;

const Root = styled.div`
  padding: ${vw(154)} ${vw(20)} ${vw(80)};
  h1 {
    font-size: ${vw(40)};
    font-weight: 300;
    line-height: 1.3;
    color: #2b2b2b;
  }
  p {
    font-size: ${vw(18)};
    font-weight: 300;
    line-height: 1.61;
    color: #2b2b2b;
    margin: ${vw(16)} 0 ${vw(80)};
  }
  @media (min-width: ${media.tablet}) {
    padding: ${vwTablet(234)} ${vwTablet(60)} ${vwTablet(140)};
    h1 {
      font-size: ${vwTablet(54)};
    }
    p {
      font-size: ${vwTablet(20)};
      margin: ${vwTablet(24)} 0 ${vwTablet(140)};
      max-width: ${vwTablet(560)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: ${vwDesktop(214)} ${vwDesktop(120)} ${vwDesktop(140)};
    h1 {
      font-size: ${vwDesktop(54)};
    }
    p {
      font-size: ${vwDesktop(20)};
      margin: ${vwDesktop(24)} 0 ${vwDesktop(140)};
      max-width: none;
    }
  }
`;
