import React from 'react';
import styled from 'styled-components';
import { withController } from 'react-scroll-parallax';

const ParallaxImage = props => {
  const handleLoad = () => {
    props.parallaxController.update();
    setTimeout(() => props.parallaxController.update(), 500);
  };
  return <Root {...props} src={props.src} onLoad={handleLoad} />;
};

export default withController(ParallaxImage);

const Root = styled.img``;
