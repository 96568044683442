import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import mapStyles from 'src/data/mapStyles';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Info = () => {
  const mapOptions = {
    zoomControl: false,
    fullscreenControl: false,
    styles: mapStyles,
  };
  return (
    <Root>
      <Container>
        <div>
          <h4>Address</h4>
          <p className="body-1">
            Thind Properties Ltd.
            <br />
            Unit 700 – 4211 Kingsway
            <br />
            Burnaby, BC V5H 1Z6
          </p>
        </div>
        <div>
          <h4>Get in Touch</h4>
          <p className="body-1">
            <span>T</span> <a href="tel:604-451-7780">604.451.7780</a>
            <br />
            <span>F</span> <a href="tel:604-451-7740">604.451.7740</a>
            <br />
            <span>E</span> <a href="mailto:info@thind.ca">info@thind.ca</a>
          </p>
        </div>
      </Container>
      <Map>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw' }}
          defaultCenter={{ lat: 49.232524, lng: -123.01092 }}
          defaultZoom={16}
          options={mapOptions}
        >
          <Icon
            src={require('src/assets/images/icons/location-pin.svg')}
            lat={49.232524}
            lng={-123.01092}
          />
        </GoogleMapReact>
      </Map>
    </Root>
  );
};

export default Info;

const Root = styled.div`
  h4 {
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
  }
  a,
  p {
    color: ${({ theme }) => theme.color.black};
  }
  p {
    margin-bottom: ${vw(40)};
  }
  span {
    font-weight: 500;
  }
  @media (min-width: ${media.tablet}) {
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
    p {
      margin-bottom: ${vwTablet(60)};
    }
  }
  @media (min-width: ${media.desktop}) {
    width: ${vwDesktop(588)};
    h4 {
      margin-bottom: ${vwDesktop(12)};
    }
    p {
      margin-bottom: ${vwDesktop(60)};
    }
  }
`;

const Container = styled.div`
  padding: 0 ${vw(20)};
  @media (min-width: ${media.tablet}) {
    padding: 0;
    display: flex;
    > div:first-of-type {
      width: ${vwTablet(282)};
      margin-right: ${vwTablet(54)};
    }
  }
  @media (min-width: ${media.desktop}) {
    > div:first-of-type {
      width: ${vwDesktop(282)};
      margin-right: ${vwDesktop(80)};
    }
  }
`;

const Map = styled.div`
  width: 100%;
  height: ${vw(320)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(320)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(320)};
  }
`;

const Icon = styled.img`
  width: ${vw(48)};
  height: ${vw(60)};
  transform: translate(-50%, -100%);
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(48)};
    height: ${vwTablet(60)};
  }
  @media (min-width: ${media.tablet}) {
    width: ${vwDesktop(48)};
    height: ${vwDesktop(60)};
  }
`;
