import React from 'react';
import styled from 'styled-components';

import Quote from 'components/Quote';
import Wrapper from 'components/PageLayout/elements/Wrapper';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ToifaAwards = () => {
  return (
    <Root>
      <Wrapper>
        <h4>TOIFA AWARDS</h4>
        <h3>Bringing the Times of India Film Awards to Vancouver</h3>
        <Quote text="“Viewed by more than 400 million people around the world, Thind was an integral partner in helping to bring TOIFA to the host city of Vancouver. It gives the team great pleasure and pride in being a part of such a landmark event and we look forward to our next great global event.”" />
        <p className="body-1 description">
          Thind reaches beyond developing new communities to bringing
          entertainment and the stars to communities around the world by being
          the official presenters of the Times of India Film Awards (TOIFA) 2013
          in Vancouver.
          <br />
          <br />
          Viewed by more than 400 million people around the world, we were an
          integral partner in helping to bring TOIFA to the host city of
          Vancouver, and it gives us great pleasure and pride in being a part of
          such a landmark event and we look forward to our next great global
          event.
        </p>
      </Wrapper>
      <Grid>
        <img
          src={require('src/assets/images/entertainment/TOIFA-Presenting-Thind.jpg')}
          alt="Thind Entertainment"
        />
        <img
          src={require('src/assets/images/entertainment/TOIFA-Redcarpet.jpg')}
          alt="Thind Entertainment"
        />
        <img
          src={require('src/assets/images/entertainment/Thind-Presenting-TOIFA-Award.jpg')}
          alt="Thind Entertainment"
        />
        <img
          src={require('src/assets/images/entertainment/red-carpet-TOIFA.jpg')}
          alt="Thind Entertainment"
        />
        <img
          src={require('src/assets/images/entertainment/Thind-Family-at-TOIFA.jpg')}
          alt="Thind Entertainment"
        />
      </Grid>
    </Root>
  );
};

export default ToifaAwards;

const Root = styled.div`
  margin: ${vw(80)} 0;
  .description {
    padding: ${vw(60)} 0 ${vw(32)};
  }
  @media (min-width: ${media.tablet}) {
    margin: ${vwTablet(140)} 0;
    .description {
      padding: ${vwTablet(100)} 0 ${vwTablet(40)};
      max-width: ${vwTablet(534)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin: ${vwDesktop(140)} 0;
    .description {
      padding: ${vwDesktop(100)} 0 ${vwDesktop(40)};
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 ${vw(20)};
  img {
    width: 100%;
    height: ${vw(160)};
    object-fit: cover;
    margin-bottom: ${vw(2)};
  }
  img:nth-of-type(2) {
    width: ${vw(152)};
  }
  img:nth-of-type(3) {
    width: ${vw(126)};
  }
  img:nth-of-type(4) {
    width: ${vw(100)};
  }
  img:nth-of-type(5) {
    width: ${vw(178)};
  }
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(60)};
    display: grid;
    grid-template-columns: ${vwTablet(232)} ${vwTablet(199)} ${vwTablet(209)};
    grid-template-rows: ${vwTablet(217)} ${vwTablet(69)} ${vwTablet(144)};
    grid-gap: ${vwTablet(4)};
    margin: 0;
    img {
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }
    img:first-of-type {
      width: 100%;
      grid-column: 1 / 3;
    }
    img:nth-of-type(2) {
      width: 100%;
      grid-row: 2 / 4;
      grid-column: 1;
    }
    img:nth-of-type(3) {
      width: 100%;
      grid-row: 2 / 4;
      grid-column: 2 / 3;
    }
    img:nth-of-type(4) {
      width: 100%;
      grid-column: 3;
      grid-row: 1 / 3;
    }
    img:nth-of-type(5) {
      width: 100%;
      grid-column: 3;
      grid-row: 3;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding: 0;
    grid-template-columns: ${vwDesktop(320)} ${vwDesktop(274)} ${vwDesktop(288)};
    grid-template-rows: ${vwDesktop(300)} ${vwDesktop(94)} ${vwDesktop(200)};
    grid-gap: ${vwDesktop(6)};
  }
`;
