import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { connect } from 'react-redux';

import Container from 'components/Container';
import Navigation from './elements/Navigation';

import { vw, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

class PageLayout extends Component {
  constructor(props) {
    super(props);
    this.$sectionRefs = this.props.sections.map(() => {
      return React.createRef();
    });
  }

  state = {
    activeSection: 0,
    offsetHeight: 0,
    scrollTop: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    this.setState({
      scrollTop: e.target.scrollingElement.scrollTop,
      offsetHeight: e.target.scrollingElement.offsetHeight,
    });
  };

  // this calculates the percentage that the user has scrolled between each section. the first section is slightly different because it takes into account the hero section as well for the scroll.
  handleWidth = index =>
    index === 0 && this.$sectionRefs[0].current
      ? `${((this.state.scrollTop + this.state.offsetHeight) /
          (this.$sectionRefs[0].current.offsetTop +
            this.$sectionRefs[0].current.offsetHeight)) *
          100}%`
      : this.$sectionRefs[index].current &&
        `${((this.state.scrollTop +
          this.state.offsetHeight -
          this.$sectionRefs[index].current.offsetTop) /
          this.$sectionRefs[index].current.offsetHeight) *
          100}%`;

  handleSection = (e, index) => {
    this.setState({ activeSection: index });
  };

  render() {
    const { title, description, hero, sections, larger, about } = this.props;
    return (
      <Root>
        <Container>
          <h1>{title}</h1>
          <Description className="body-large" larger={larger}>
            {description}
          </Description>
        </Container>
        <Hero about={about}>
          <img
            src={hero}
            alt={title}
            style={
              1.1 - this.state.scrollTop * 0.0001 > 1
                ? {
                    transform: `scale(${1.1 - this.state.scrollTop * 0.0001})`,
                  }
                : { transform: 'scale(1)' }
            }
          />
        </Hero>
        <Wrapper>
          <Navigation
            activeSection={this.state.activeSection}
            handleWidth={this.handleWidth}
            sections={sections}
          />
          <div>
            {sections.map((item, index) => (
              <Waypoint key={index} onEnter={e => this.handleSection(e, index)}>
                <div ref={this.$sectionRefs[index]}>{item.section}</div>
              </Waypoint>
            ))}
          </div>
        </Wrapper>
      </Root>
    );
  }
}

PageLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hero: PropTypes.string,
  sections: PropTypes.array,
  larger: PropTypes.bool,
  about: PropTypes.bool,
};

const mapStateToProps = state => ({
  browser: state.browser,
});

export default connect(
  mapStateToProps,
  {}
)(PageLayout);

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Root = styled.div`
  padding-top: ${vw(154)};
  color: ${({ theme }) => theme.color.black};
  h1 {
    margin-bottom: ${vw(16)};
  }
  h4 {
    color: ${({ theme }) => theme.color.blue};
    margin-bottom: ${vw(12)};
  }
  h3 {
    margin-bottom: ${vw(24)};
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(234)};
    h1 {
      margin-bottom: ${vwTablet(24)};
    }
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
    h3 {
      margin-bottom: ${vwTablet(24)};
      max-width: ${vwTablet(539)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(214)};
    h1 {
      margin-bottom: ${vwDesktop(24)};
      opacity: 0;
      animation: ${fadeUp} 0.3s ease-in-out forwards;
      animation-delay: 0.7s;
    }
    h4 {
      margin-bottom: ${vwDesktop(12)};
    }
    h3 {
      margin-bottom: ${vwDesktop(24)};
      max-width: ${vwDesktop(588)};
    }
  }
`;

const Description = styled.p`
  @media (min-width: ${media.tablet}) {
    max-width: ${vwTablet(560)};
  }
  @media (min-width: ${media.desktop}) {
    opacity: 0;
    animation: ${fadeUp} 0.3s ease-in-out forwards;
    animation-delay: 0.9s;
    max-width: ${props => (props.larger ? vwDesktop(840) : vwDesktop(792))};
  }
`;

const Hero = styled.div`
  width: 100%;
  height: ${vw(200)};
  overflow: hidden;
  margin: ${vw(80)} 0 ${vw(40)};
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(360)};
    margin: ${vwTablet(140)} 0 ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(480)};
    margin: ${vwDesktop(80)} auto ${vwDesktop(80)};
    width: ${vwDesktop(1200)};
    opacity: 0;
    animation: ${fadeUp} 0.3s ease-in-out forwards;
    animation-delay: 1.1s;
  }
  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @media (min-width: ${media.desktop}) {
      object-position: ${props => (props.about ? 'center 35%' : 'center')};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.desktop}) {
    display: flex;
    padding: 0 ${vwDesktop(120)};
  }
`;
