import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet } from 'src/styles/utils';

const Hamburger = props => {
  const { handleClick, isOpen, isTransparent } = props;
  return (
    <Root
      open={isOpen}
      isTransparent={isTransparent}
      onClick={() => handleClick()}
      className={isOpen ? 'close' : ''}
    >
      <span />
      <span />
      <span />
      <span />
    </Root>
  );
};

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
  isTransparent: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  width: ${vw(24)};
  height: ${vw(22)};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s;
  opacity: 1;
  @media (min-width: ${media.tablet}) {
    width: ${vwTablet(24)};
    height: ${vwTablet(22)};
  }
  @media (min-width: ${media.desktop}) {
    display: none;
  }
  span {
    position: absolute;
    width: 100%;
    background: ${props =>
      props.open || !props.isTransparent ? props.theme.color.black : 'white'};
    height: ${vw(3)};
    min-height: 1px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.15s;
    @media (min-width: ${media.tablet}) {
      height: ${vwTablet(3)};
      min-height: 1px;
    }
    &:nth-child(1) {
      top: 0;
      transform: translateX(-50%) scaleX(1);
    }
    &:nth-child(2) {
      width: 100%;
      top: 50%;
      left: unset;
      right: 0%;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      width: 100%;
      top: 50%;
      left: unset;
      right: 0%;
      transform: translateY(-50%);
      opacity: 0;
    }
    &:nth-child(4) {
      bottom: 0;
      transform: translateX(-50%) scaleX(1);
    }
  }
  &.close {
    span {
      &:nth-child(1) {
        top: 0;
        transform: translateX(-50%) scaleX(0);
      }
      &:nth-child(2) {
        top: 50%;
        transform: translate(0, 0) rotate(45deg);
      }
      &:nth-child(3) {
        top: 50%;
        transform: translate(0, 0) rotate(-45deg);
        opacity: 1;
      }
      &:nth-child(4) {
        bottom: 0;
        transform: translateX(-50%) scaleX(0);
      }
    }
  }
`;

export default Hamburger;
